export function dispatchShow() {
	return {
		state: {
			DispatchHeaderShow: true,
			DispatchGetNumberShow: false,
			DispatchCustomersShow: true,
			DispatchSelectedCustomerShow: false,
			DispatchLinesShow: false,
			DispatchLineEditShow: false,
			DispatchFooterButtonsShow: false,
		},
		getters: {
			getDispatchHeaderShow: (state) => state.DispatchHeaderShow,
			getDispatchGetNumberShow: (state) => state.DispatchGetNumberShow,
			getDispatchCustomersShow: (state) => state.DispatchCustomersShow,
			getDispatchSelectedCustomerShow: (state) =>
				state.DispatchSelectedCustomerShow,
			getDispatchLinesShow: (state) => state.DispatchLinesShow,
			getDispatchLineEditShow: (state) => state.DispatchLineEditShow,
			getDispatchFooterButtonsShow: (state) => state.DispatchFooterButtonsShow,
		},
		mutations: {
			mutateDispatchHeaderShow(state, payload) {
				state.DispatchHeaderShow = payload
			},
			mutateDispatchGetNumberShow(state, payload) {
				state.DispatchGetNumberShow = payload
			},
			mutateDispatchCustomersShow(state, payload) {
				state.DispatchCustomersShow = payload
			},
			mutateDispatchSelectedCustomerShow(state, payload) {
				state.DispatchSelectedCustomerShow = payload
			},
			mutateDispatchLinesShow(state, payload) {
				state.DispatchLinesShow = payload
			},
			mutateDispatchLineEditShow(state, payload) {
				state.DispatchLineEditShow = payload
			},
			mutateDispatchFooterButtonsShow(state, payload) {
				state.DispatchFooterButtonsShow = payload
			},
		},
		actions: {
			setDispatchHeaderShow({ commit }, payload) {
				commit('mutateDispatchHeaderShow', payload)
			},
			setDispatchGetNumberShow({ commit }, payload) {
				commit('mutateDispatchGetNumberShow', payload)
			},
			setDispatchCustomersShow({ commit }, payload) {
				commit('mutateDispatchCustomersShow', payload)
			},
			setDispatchSelectedCustomerShow({ commit }, payload) {
				commit('mutateDispatchSelectedCustomerShow', payload)
			},
			setDispatchLinesShow({ commit }, payload) {
				commit('mutateDispatchLinesShow', payload)
			},
			setDispatchLineEditShow({ commit }, payload) {
				commit('mutateDispatchLineEditShow', payload)
			},
			setDispatchFooterButtonsShow({ commit }, payload) {
				commit('mutateDispatchFooterButtonsShow', payload)
			},
		},
	}
}
