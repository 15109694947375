export function address() {
	return {
		state: {
			formAddAddressKey: 1,
		},
		getters: {
			getFormAddAddressKey: (state) => state.formAddAddressKey,
		},
		mutations: {
			mutateFormAddAddressKey(state, payload) {
				state.formAddAddressKey = payload
			},
		},
		actions: {
			setFormAddAddressKey({ commit }, payload) {
				commit('mutateFormAddAddressKey', payload)
			},
		},
	}
}
