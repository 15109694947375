const en = {
	message: {
		pages: {
			home: {
				msg: 'Hello, Welcome',
			},
			navbar: {
				language: 'English',
				login: 'Log in',
				logout: 'Log out',
				home: 'Home',
				stock: 'Stock',
				companies: 'Companies',
				orderOld: 'Order Old',
				order: 'Order',
				dispatch: 'Dispatch',
				invoice: 'Invoice',
				accounting: 'Accounting',
				dashboard: 'Dashboard',
				settings: 'Settings',
			},
			login: {
				login: 'Log in',
				email: 'Email',
				password: 'Password',
				rememberme: 'Remember me',
				ex: 'e.g.',
				message: 'You must log in',
			},
			footer: {
				switchTheLanguage: 'Switch the Language',
				switchTheCompany: 'Switch the Company',
				user: 'User: ',
				company: 'Selected Company: ',
				switchCompanyMessage: 'Switch the company',
				unselected: 'Unselected',
			},
			card: {
				moreinfo: 'More Info',
				edit: 'Edit',
			},
			vidle: {
				line1: 'You are idle for 4 minutes 30 seconds.',
				line2: 'After 30 seconds you will be logged out automatically!',
				line3: 'If you want to stay logged in then click OK!',
				msg: 'You have been logged out',
			},
			errors: {
				e404: {
					message: 'Sorry, nothing here ...',
					back: 'Go back',
				},
			},
		},
	},
}

export default en
