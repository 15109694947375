import Vue from 'vue'
import Vuex from 'vuex'
// import { lsGetLogInfo } from '../plugins/lsstore/index'
import createPersistedState from 'vuex-persistedstate'
import SecureLS from 'secure-ls'

const ls = new SecureLS({
	encodingType: 'des',
	encryptionSecret:
		"u4p<9B[NDA'/'G\"[5rRK/175EC}uf`s)(:}z[PCGP980}{V[_ng|^[c7]#BiY8a",
})
import { start } from './modules/startup/moduleStart'
const moduleStart = start()
import { startOurCompanies } from './modules/startup/moduleStartOurCompanies.js'
const moduleStartOurCompanies = startOurCompanies()
import { multiDocuments } from './modules/v3-modules/moduleMultiDocuments.js'
const moduleMultiDocuments = multiDocuments()
import { routeGuards } from './modules/startup/moduleStartRouteGuards.js'
const moduleGuards = routeGuards()
import { loadingCircle } from './modules/v3-modules/moduleLoadingCircle.js'
const moduleLoadingCircle = loadingCircle()
import { document } from './modules/v3-modules/moduleDocuments.js'
const moduleDocument = document()
import { startLogos } from './modules/startup/moduleStartLogos'
const moduleStartLogos = startLogos()
import { stock } from './modules/oldies/moduleStock'
const moduleStock = stock()
import { formAddStock } from './modules/oldies/moduleStockFormAdd'
const moduleFormAddStock = formAddStock()
import { customer } from './modules/oldies/moduleCustomer'
const moduleCustomer = customer()
import { customerPriceList } from './modules/oldies/moduleCustomerPriceList'
const moduleCustomerPriceList = customerPriceList()
import { telephone } from './modules/oldies/moduleTelephone'
const moduleTelephone = telephone()
import { address } from './modules/oldies/moduleAddress'
const moduleAddress = address()
import { order } from './modules/oldies/moduleOrder'
const moduleOrder = order()
import { orderEdit } from './modules/oldies/moduleOrderEdit'
const moduleOrderEdit = orderEdit()
import { stockTabKey } from './modules/oldies/moduleStockTabKey'
const moduleStockTabKey = stockTabKey()
import { selectStockItem } from './modules/oldies/moduleOrderEditSelectStockItem'
const moduleOrderEditSelectStockItem = selectStockItem()
import { insufficientStock } from './modules/oldies/moduleInsufficientStock'
const moduleInsufficientStock = insufficientStock()
import { dispatch } from './modules/oldies/moduleDispatch'
const moduleDispatch = dispatch()
import { dispatchLayout } from './modules/oldies/moduleDispatchLayout'
const moduleDispatchLayout = dispatchLayout()
import { dispatchShow } from './modules/oldies/moduleDispatchShow'
const moduleDispatchShow = dispatchShow()
import { dispatchEdit } from './modules/oldies/moduleDispatchEdit'
const moduleDispatchEdit = dispatchEdit()
import { customerPrices } from './modules/oldies/moduleOrderCustomerPrices'
const moduleOrderCustomerPrices = customerPrices()
import { invoice } from './modules/oldies/moduleInvoice'
const moduleInvoice = invoice()
import { invoiceShow } from './modules/oldies/moduleInvoiceShow'
const moduleInvoiceShow = invoiceShow()
import { invoiceEdit } from './modules/oldies/moduleInvoiceEdit'
const moduleInvoiceEdit = invoiceEdit()
import { invoiceLayout } from './modules/oldies/moduleInvoiceLayout'
const moduleInvoiceLayout = invoiceLayout()
import { dispatchReport } from './modules/oldies/moduleDispatcReport'
const moduleDispatchReport = dispatchReport()
import { invoiceReport } from './modules/oldies/moduleInvoiceReport'
const moduleInvoiceReport = invoiceReport()
import { keys } from './modules/startup/moduleKeys'
const moduleKeys = keys()
import { storeAddress } from './modules/startup/moduleCompanyAddress'
const moduleAddressComp = storeAddress()
import { storeCompanyInfo } from './modules/startup/moduleCompanyInfo'
const moduleCompanyInfo = storeCompanyInfo()
import { selectedDocuments } from './modules/startup/moduleSelectedDocuments'
const modulesSelectedDocuments = selectedDocuments()
import { storeDocument } from './modules/document-state/moduleDocumentState'
const modulesStoreDocument = storeDocument()
import { storeDocumentLines } from './modules/document-state/moduleDocumentLinesState'
const modulesStoreDocumentLines = storeDocumentLines()
import { storeDocumentSelectedCompany } from './modules/document-state/moduleDocumentSelectedCompanyState'
const modulesStoreDocumentSelectedCompany = storeDocumentSelectedCompany()
Vue.use(Vuex)
export default new Vuex.Store({
	paths: ['modules/startup', 'modules/oldies'],
	plugins: [
		createPersistedState({
			key: 'st',
			storage: {
				getItem: (key) => ls.get(key),
				setItem: (key, value) => ls.set(key, value),
				removeItem: (key) => ls.remove(key),
			},
		}),
	],
	modules: {
		moduleStart,
		moduleStartOurCompanies,
		moduleMultiDocuments,
		moduleGuards,
		moduleLoadingCircle,
		moduleDocument,
		moduleStartLogos,
		moduleStock,
		moduleStockTabKey,
		moduleInsufficientStock,
		moduleFormAddStock,
		moduleCustomer,
		moduleCustomerPriceList,
		moduleTelephone,
		moduleAddress,
		moduleOrder,
		moduleOrderEdit,
		moduleOrderEditSelectStockItem,
		moduleDispatch,
		moduleDispatchLayout,
		moduleDispatchShow,
		moduleDispatchEdit,
		moduleOrderCustomerPrices,
		moduleInvoice,
		moduleInvoiceShow,
		moduleInvoiceEdit,
		moduleInvoiceLayout,
		moduleDispatchReport,
		moduleInvoiceReport,
		moduleKeys,
		moduleAddressComp,
		moduleCompanyInfo,
		modulesSelectedDocuments,
		// version 2
		modulesStoreDocument,
		modulesStoreDocumentLines,
		modulesStoreDocumentSelectedCompany,
	},
})
