export function dispatch() {
	return {
		state: {
			DispatchNumber: '',
			DispatchCompanyID: '',
			DispatchCustomerID: '',
			DispatchDate: '',
			DispatchLock: '',
			DispatchCanceled: '',
			DispatchCustomerNickName: '',
			DispatchCustomerTitle: '',
			DispatchCustomerOrgNum: '',
			DispatchLayoutKey: 1,
		},
		getters: {
			getDispatchNumber: (state) => state.DispatchNumber,
			getDispatchCompanyID: (state) => state.DispatchCompanyID,
			getDispatchCustomerID: (state) => state.DispatchCustomerID,
			getDispatchDate: (state) => state.DispatchDate,
			getDispatchLock: (state) => state.DispatchLock,
			getDispatchCanceled: (state) => state.DispatchCanceled,
			getDispatchCustomerNickName: (state) => state.DispatchCustomerNickName,
			getDispatchCustomerTitle: (state) => state.DispatchCustomerTitle,
			getDispatchCustomerOrgNum: (state) => state.DispatchCustomerOrgNum,
			getDispatchLayoutKey: (state) => state.DispatchLayoutKey,
		},
		mutations: {
			mutateDispatchNumber(state, payload) {
				state.DispatchNumber = payload
			},
			mutateDispatchCompanyID(state, payload) {
				state.DispatchCompanyID = payload
			},
			mutateDispatchCustomerID(state, payload) {
				state.DispatchCustomerID = payload
			},
			mutateDispatchDate(state, payload) {
				state.DispatchDate = payload
			},
			mutateDispatchLock(state, payload) {
				state.DispatchLock = payload
			},
			mutateDispatchCanceled(state, payload) {
				state.DispatchCanceled = payload
			},
			mutateDispatchCustomerNickName(state, payload) {
				state.DispatchCustomerNickName = payload
			},
			mutateDispatchCustomerTitle(state, payload) {
				state.DispatchCustomerTitle = payload
			},
			mutateDispatchCustomerOrgNum(state, payload) {
				state.DispatchCustomerOrgNum = payload
			},
			mutateDispatchLayoutKey(state, payload) {
				state.DispatchLayoutKey = payload
			},
		},
		actions: {
			setDispatchNumber({ commit }, payload) {
				commit('mutateDispatchNumber', payload)
			},
			setDispatchCompanyID({ commit }, payload) {
				commit('mutateDispatchCompanyID', payload)
			},
			setDispatchCustomerID({ commit }, payload) {
				commit('mutateDispatchCustomerID', payload)
			},
			setDispatchDate({ commit }, payload) {
				commit('mutateDispatchDate', payload)
			},
			setDispatchLock({ commit }, payload) {
				commit('mutateDispatchLock', payload)
			},
			setDispatchCanceled({ commit }, payload) {
				commit('mutateDispatchCanceled', payload)
			},
			setDispatchCustomerNickName({ commit }, payload) {
				commit('mutateDispatchCustomerNickName', payload)
			},
			setDispatchCustomerTitle({ commit }, payload) {
				commit('mutateDispatchCustomerTitle', payload)
			},
			setDispatchCustomerOrgNum({ commit }, payload) {
				commit('mutateDispatchCustomerOrgNum', payload)
			},
			setDispatchLayoutKey({ commit }, payload) {
				commit('mutateDispatchLayoutKey', payload)
			},
		},
	}
}
