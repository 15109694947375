export function loadingCircle() {
	return {
		state: {
			isLoadingCompanies: true,
			isLoadingLogo: true,
			isGuardSet: false,
		},
		getters: {
			getIsLoadingCompanies: (state) => state.isLoadingCompanies,
			getIsLoadingLogo: (state) => state.isLoadingLogo,
			getIsGuardSet: (state) => state.isGuardSet,
		},
		mutations: {
			mutateIsLoadingCompanies(state, payload) {
				state.isLoadingCompanies = payload
			},
			mutateIsLoadingLogo(state, payload) {
				state.isLoadingLogo = payload
			},
			mutateIsGuardSet(state, payload) {
				state.isGuardSet = payload
			},
		},
		actions: {
			setIsLoadingCompanies({ commit }, payload) {
				commit('mutateIsLoadingCompanies', payload)
			},
			setIsLoadingLogo({ commit }, payload) {
				commit('mutateIsLoadingLogo', payload)
			},
			setIsGuardSet({ commit }, payload) {
				commit('mutateIsGuardSet', payload)
			},
		},
	}
}
