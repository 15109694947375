export function multiDocuments() {
	return {
		state: {
			DailyInvoicesData: [],
			DailyDispatchesData: [],
			DailyOrdersData: [],
			DailyRemindersData: [],
			ShowProcessReport: [],
		},
		getters: {
			getDailyInvoicesData: (state) => state.DailyInvoicesData,
			getDailyDispatchesData: (state) => state.DailyDispatchesData,
			getDailyOrdersData: (state) => state.DailyOrdersData,
			getDailyRemindersData: (state) => state.DailyRemindersData,
			getShowProcessReport: (state) => state.ShowProcessReport,
		},
		mutations: {
			mutateDailyInvoicesData(state, payload) {
				state.DailyInvoicesData = payload
			},
			mutateDailyDispatchesData(state, payload) {
				state.DailyDispatchesData = payload
			},
			mutateDailyOrdersData(state, payload) {
				state.DailyOrdersData = payload
			},
			mutateDailyRemindersData(state, payload) {
				state.DailyRemindersData = payload
			},
			mutateShowProcessReport(state, payload) {
				state.ShowProcessReport = payload
			},
		},
		actions: {
			setDailyInvoicesData({ commit }, payload) {
				commit('mutateDailyInvoicesData', payload)
			},
			setDailyDispatchesData({ commit }, payload) {
				commit('mutateDailyDispatchesData', payload)
			},
			setDailyOrdersData({ commit }, payload) {
				commit('mutateDailyOrdersData', payload)
			},
			setDailyRemindersData({ commit }, payload) {
				commit('mutateDailyRemindersData', payload)
			},
			setShowProcessReport({ commit }, payload) {
				commit('mutateShowProcessReport', payload)
			},
		},
	}
}
