const tr = {
	message: {
		pages: {
			home: {
				msg: 'Merhaba, Hoşgeldiniz',
			},
			navbar: {
				language: 'Türkçe',
				login: 'Oturum Aç',
				logout: 'Oturumu kapat',
				home: 'Ana Sayfa',
				stock: 'Stok',
				customer: 'Müşteri',
				order: 'Sipariş',
				dispatch: 'İrsaliye',
				invoice: 'Fatura',
				accounting: 'Muhasebe',
				dashboard: 'Kontrol Paneli',
				settings: 'Ayarlar',
			},
			aboutus: {
				aboutus: 'Hakkımızda',
			},
			login: {
				login: 'Oturum aç',
				email: 'Eposta',
				password: 'Şifre',
				rememberme: 'Bilgileri hatırla',
				ex: 'ör:',
				message: 'Oturum Açmalısınız',
			},
			footer: {
				switchTheLanguage: 'Dili değiştir',
				switchTheCompany: 'Firmayı değiştir',
				user: 'Kullanıcı: ',
				company: 'Firma: ',
				switchCompanyMessage: 'Firma seç',
				unselected: 'Seçilmemiş',
			},
			card: {
				moreinfo: 'Tum Bilgiler',
				edit: 'Düzenle',
			},
			vidle: {
				line1: '4 dakika 30 saniye işlem yapmadınız',
				line2: '30 saniye sonra oturum otomatik olarak sonlandırılacaktır!',
				line3: "Şayet oturma devam etmek istiyorsanız OK'i klikleyiniz!",
				msg: 'Oturum sonlandırıldı.',
			},
			errors: {
				e404: {
					message: 'Üzgünüz, gösterilececek bir şey bulunamadı ...',
					back: 'Geri git',
				},
			},
		},
	},
}

export default tr
