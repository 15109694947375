export function storeAddress() {
	return {
		state: {
			AddressListOurCompany: '',
			AddressListCustomer: '',
			AddressListCustomerInfo: '',
			AddressAddEditToggle: '',
		},
		getters: {
			getAddressListOurCompany: (state) => state.AddressListOurCompany,
			getAddressListCustomer: (state) => state.AddressListCustomer,
			getAddressListCustomerInfo: (state) => state.AddressListCustomerInfo,
			getAddressAddEditToggle: (state) => state.AddressAddEditToggle,
		},
		mutations: {
			mutateAddressListOurCompany(state, payload) {
				state.AddressListOurCompany = payload
			},
			mutateAddressListCustomer(state, payload) {
				state.AddressListCustomer = payload
			},
			mutateAddressListCustomerInfo(state, payload) {
				state.AddressListCustomerInfo = payload
			},
			mutateAddressAddEditToggle(state, payload) {
				state.AddressAddEditToggle = payload
			},
		},
		actions: {
			setAddressListOurCompany({ commit }, payload) {
				commit('mutateAddressListOurCompany', payload)
			},
			setAddressListCustomer({ commit }, payload) {
				commit('mutateAddressListCustomer', payload)
			},
			setAddressListCustomerInfo({ commit }, payload) {
				commit('mutateAddressListCustomerInfo', payload)
			},
			setAddressAddEditToggle({ commit }, payload) {
				commit('mutateAddressAddEditToggle', payload)
			},
		},
	}
}
