export function formAddStock() {
	return {
		state: {
			StockItemID: null,
			StockItemName: null,
			StockUnit: null,
			StockItemPrice: null,
			StockItemPriceA: null,
			StockItemPriceB: null,
			StockItemPriceC: null,
			StockItemPriceD: null,
			StockItemPriceZ: null,
			StockItemVat: null,
			StockGroup: null,
			StockItemAmount: null,
			StockAlarm: null,
			StockAlarmMessage: null,
			StockItemShelf: null,
			StockItemCampaignPrice: null,
			CampaignIsActive: false,
		},
		getters: {
			getStockItemID: (state) => state.StockItemID,
			getStockItemName: (state) => state.StockItemName,
			getStockUnit: (state) => state.StockUnit,
			getStockItemPrice: (state) => state.StockItemPrice,
			getStockItemPriceA: (state) => state.StockItemPriceA,
			getStockItemPriceB: (state) => state.StockItemPriceB,
			getStockItemPriceC: (state) => state.StockItemPriceC,
			getStockItemPriceD: (state) => state.StockItemPriceD,
			getStockItemPriceZ: (state) => state.StockItemPriceZ,
			getStockItemVat: (state) => state.StockItemVat,
			getStockGroup: (state) => state.StockGroup,
			getStockItemAmount: (state) => state.StockItemAmount,
			getStockAlarm: (state) => state.StockAlarm,
			getStockAlarmMessage: (state) => state.StockAlarmMessage,
			getStockItemShelf: (state) => state.StockItemShelf,
			getStockItemCampaignPrice: (state) => state.StockItemCampaignPrice,
			getCampaignIsActive: (state) => state.CampaignIsActive,
		},
		mutations: {
			mutateStockItemID(state, payload) {
				state.StockItemID = payload
			},
			mutateStockItemName(state, payload) {
				state.StockItemName = payload
			},
			mutateStockUnit(state, payload) {
				state.StockUnit = payload
			},
			mutateStockItemPrice(state, payload) {
				state.StockItemPrice = payload
			},
			mutateStockItemPriceA(state, payload) {
				state.StockItemPriceA = payload
			},
			mutateStockItemPriceB(state, payload) {
				state.StockItemPriceB = payload
			},
			mutateStockItemPriceC(state, payload) {
				state.StockItemPriceC = payload
			},
			mutateStockItemPriceD(state, payload) {
				state.StockItemPriceD = payload
			},
			mutateStockItemPriceZ(state, payload) {
				state.StockItemPriceZ = payload
			},
			mutateStockItemVat(state, payload) {
				state.StockItemVat = payload
			},
			mutateStockGroup(state, payload) {
				state.StockGroup = payload
			},
			mutateStockItemAmount(state, payload) {
				state.StockItemAmount = payload
			},
			mutateStockAlarm(state, payload) {
				state.StockAlarm = payload
			},
			mutateStockAlarmMessage(state, payload) {
				state.StockAlarmMessage = payload
			},
			mutateStockItemShelf(state, payload) {
				state.StockItemShelf = payload
			},
			mutateStockItemCampaignPrice(state, payload) {
				state.StockItemCampaignPrice = payload
			},
			mutateCampaignIsActive(state, payload) {
				state.CampaignIsActive = payload
			},
		},
		actions: {
			setStockItemID({ commit }, payload) {
				commit('mutateStockItemID', payload)
			},
			setStockItemName({ commit }, payload) {
				commit('mutateStockItemName', payload)
			},
			setStockUnit({ commit }, payload) {
				commit('mutateStockUnit', payload)
			},
			setStockItemPrice({ commit }, payload) {
				commit('mutateStockItemPrice', payload)
			},
			setStockItemPriceA({ commit }, payload) {
				commit('mutateStockItemPriceA', payload)
			},
			setStockItemPriceB({ commit }, payload) {
				commit('mutateStockItemPriceB', payload)
			},
			setStockItemPriceC({ commit }, payload) {
				commit('mutateStockItemPriceC', payload)
			},
			setStockItemPriceD({ commit }, payload) {
				commit('mutateStockItemPriceD', payload)
			},
			setStockItemPriceZ({ commit }, payload) {
				commit('mutateStockItemPriceZ', payload)
			},
			setStockItemVat({ commit }, payload) {
				commit('mutateStockItemVat', payload)
			},
			setStockGroup({ commit }, payload) {
				commit('mutateStockGroup', payload)
			},
			setStockItemAmount({ commit }, payload) {
				commit('mutateStockItemAmount', payload)
			},
			setStockAlarm({ commit }, payload) {
				commit('mutateStockAlarm', payload)
			},
			setStockAlarmMessage({ commit }, payload) {
				commit('mutateStockAlarmMessage', payload)
			},
			setStockItemShelf({ commit }, payload) {
				commit('mutateStockItemShelf', payload)
			},
			setStockItemCampaignPrice({ commit }, payload) {
				commit('mutateStockItemCampaignPrice', payload)
			},
			setCampaignIsActive({ commit }, payload) {
				commit('mutateCampaignIsActive', payload)
			},
		},
	}
}
