<template>
	<LayoutLogin>
		<div class="section outer-container">
			<div class="columns main-container">
				<div class="column" is-half>
					<div>
						{{ $t('message.pages.login.message') }}
					</div>
					<div><br /></div>
					<div>
						<b-button v-on:click="handleLogin" class="is-primary login-btn">
							{{ $t('message.pages.login.login') }}
						</b-button>
					</div>
				</div>
			</div>
		</div>
	</LayoutLogin>
</template>

<script>
export default {
	name: 'Login',
	methods: {
		handleLogin() {
			this.$auth.login()
		},
	},
	mounted() {
		this.$store.dispatch('setIsLoadingCompanies', true)
		this.$store.dispatch('setIsLoadingLogo', true)
		this.$store.dispatch('setIsGuardSet', false)
	},
}
</script>
<style scoped>
#main-container {
	padding-left: 30px;
	padding-right: 30px;
	margin: auto;
	width: 80%;
	background: beige;
}
#outer-container {
	max-width: 960px;
	background: #42b983;
}
.login-btn {
	margin-left: 10px;
}
</style>
