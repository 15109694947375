export function invoice() {
	return {
		state: {
			InvoiceNumber: '',
			InvoiceCompanyID: '',
			InvoiceCustomerID: '',
			InvoiceDate: '',
			InvoiceDueDate: '',
			InvoiceLock: '',
			InvoiceCanceled: '',
			InvoiceCustomerNickName: '',
			InvoiceCustomerTitle: '',
			InvoiceCustomerOrgNum: '',
			InvoiceCustomerTerm: '',
			InvoiceLayoutKey: 1,
		},
		getters: {
			getInvoiceNumber: (state) => state.InvoiceNumber,
			getInvoiceCompanyID: (state) => state.InvoiceCompanyID,
			getInvoiceCustomerID: (state) => state.InvoiceCustomerID,
			getInvoiceDate: (state) => state.InvoiceDate,
			getInvoiceDueDate: (state) => state.InvoiceDueDate,
			getInvoiceLock: (state) => state.InvoiceLock,
			getInvoiceCanceled: (state) => state.InvoiceCanceled,
			getInvoiceCustomerNickName: (state) => state.InvoiceCustomerNickName,
			getInvoiceCustomerTitle: (state) => state.InvoiceCustomerTitle,
			getInvoiceCustomerOrgNum: (state) => state.InvoiceCustomerOrgNum,
			getInvoiceCustomerTerm: (state) => state.InvoiceCustomerTerm,
			getInvoiceLayoutKey: (state) => state.InvoiceLayoutKey,
		},
		mutations: {
			mutateInvoiceNumber(state, payload) {
				state.InvoiceNumber = payload
			},
			mutateInvoiceCompanyID(state, payload) {
				state.InvoiceCompanyID = payload
			},
			mutateInvoiceCustomerID(state, payload) {
				state.InvoiceCustomerID = payload
			},
			mutateInvoiceDate(state, payload) {
				state.InvoiceDate = payload
			},
			mutateInvoiceDueDate(state, payload) {
				state.InvoiceDueDate = payload
			},
			mutateInvoiceLock(state, payload) {
				state.InvoiceLock = payload
			},
			mutateInvoiceCanceled(state, payload) {
				state.InvoiceCanceled = payload
			},
			mutateInvoiceCustomerNickName(state, payload) {
				state.InvoiceCustomerNickName = payload
			},
			mutateInvoiceCustomerTitle(state, payload) {
				state.InvoiceCustomerTitle = payload
			},
			mutateInvoiceCustomerOrgNum(state, payload) {
				state.InvoiceCustomerOrgNum = payload
			},
			mutateInvoiceCustomerTerm(state, payload) {
				state.InvoiceCustomerTerm = payload
			},
			mutateInvoiceLayoutKey(state, payload) {
				state.InvoiceLayoutKey = payload
			},
		},
		actions: {
			setInvoiceNumber({ commit }, payload) {
				commit('mutateInvoiceNumber', payload)
			},
			setInvoiceCompanyID({ commit }, payload) {
				commit('mutateInvoiceCompanyID', payload)
			},
			setInvoiceCustomerID({ commit }, payload) {
				commit('mutateInvoiceCustomerID', payload)
			},
			setInvoiceDate({ commit }, payload) {
				commit('mutateInvoiceDate', payload)
			},
			setInvoiceDueDate({ commit }, payload) {
				commit('mutateInvoiceDueDate', payload)
			},
			setInvoiceLock({ commit }, payload) {
				commit('mutateInvoiceLock', payload)
			},
			setInvoiceCanceled({ commit }, payload) {
				commit('mutateInvoiceCanceled', payload)
			},
			setInvoiceCustomerNickName({ commit }, payload) {
				commit('mutateInvoiceCustomerNickName', payload)
			},
			setInvoiceCustomerTitle({ commit }, payload) {
				commit('mutateInvoiceCustomerTitle', payload)
			},
			setInvoiceCustomerOrgNum({ commit }, payload) {
				commit('mutateInvoiceCustomerOrgNum', payload)
			},
			setInvoiceCustomerTerm({ commit }, payload) {
				commit('mutateInvoiceCustomerTerm', payload)
			},
			setInvoiceLayoutKey({ commit }, payload) {
				commit('mutateInvoiceLayoutKey', payload)
			},
		},
	}
}
