export function order() {
	return {
		state: {
			KeySelectCustomer: 1,
			ShowSelectSearchForm: true,
			SelectedCustomerID: '',
			SelectedCustomerNickname: '',
			SelectedCustomerTitle: '',
			ShowChangeCustomerBtn: true,
			OrderAddOrEdit: '',
			OrderDate: '',
			OrderNumber: '',
			OrderType: '',
			OrderLanguage: '',
			OrderExchangeUnit: '',
			OrderExchangeRate: '',
			OrderAddEditToggle: '',
			KeySetOrderHeadersDetails: 1,
			ShowOrderHeader: false,
			ShowForm: false,
			ShowAddOrderForm: true,
			ShowAddBtn: false,
			ShowOrderLines: false,
			SelectedStockItem: '',
			OrderLineInfo: '',
			ShowOrderSelectStock: false,
			ShowFormAddOrderLines: false,
			GoodsTransactionUUID: '',
			ShowSaveAndCloseBtn: false,
			KeyFormAddOrderLines: 1,
			KeyFormOrderHeaders: 1,
			ShowExchangeButtonOld: true,
			ShowExchangeFieldsOld: false,
		},
		getters: {
			getKeySelectCustomer: (state) => state.KeySelectCustomer,
			getShowSelectSearchForm: (state) => state.ShowSelectSearchForm,
			getSelectedCustomerID: (state) => state.SelectedCustomerID,
			getSelectedCustomerNickname: (state) => state.SelectedCustomerNickname,
			getSelectedCustomerTitle: (state) => state.SelectedCustomerTitle,
			getShowChangeCustomerBtn: (state) => state.ShowChangeCustomerBtn,
			getOrderAddOrEdit: (state) => state.OrderAddOrEdit,
			getOrderDate: (state) => state.OrderDate,
			getOrderNumber: (state) => state.OrderNumber,
			getOrderType: (state) => state.OrderType,
			getOrderLanguage: (state) => state.OrderLanguage,
			getOrderExchangeUnit: (state) => state.OrderExchangeUnit,
			getOrderExchangeRate: (state) => state.OrderExchangeRate,
			getOrderAddEditToggle: (state) => state.OrderAddEditToggle,
			getKeySetOrderHeadersDetails: (state) => state.KeySetOrderHeadersDetails,
			getShowOrderHeader: (state) => state.ShowOrderHeader,
			getShowForm: (state) => state.ShowForm,
			getShowAddOrderForm: (state) => state.ShowAddOrderForm,
			getShowAddBtn: (state) => state.ShowAddBtn,
			getShowOrderLines: (state) => state.ShowOrderLines,
			getSelectedStockItem: (state) => state.SelectedStockItem,
			getOrderLineInfo: (state) => state.OrderLineInfo,
			getShowOrderSelectStock: (state) => state.ShowOrderSelectStock,
			getShowFormAddOrderLines: (state) => state.ShowFormAddOrderLines,
			getGoodsTransactionUUID: (state) => state.GoodsTransactionUUID,
			getShowSaveAndCloseBtn: (state) => state.ShowSaveAndCloseBtn,
			getKeyFormAddOrderLines: (state) => state.KeyFormAddOrderLines,
			getKeyFormOrderHeaders: (state) => state.KeyFormOrderHeaders,
			getShowExchangeButtonOld: (state) => state.ShowExchangeButtonOld,
			getShowExchangeFieldsOld: (state) => state.ShowExchangeFieldsOld,
		},
		mutations: {
			mutateKeySelectCustomer(state, payload) {
				state.KeySelectCustomer = payload
			},
			mutateShowSelectSearchForm(state, payload) {
				state.ShowSelectSearchForm = payload
			},
			mutateSelectedCustomerID(state, payload) {
				state.SelectedCustomerID = payload
			},
			mutateSelectedCustomerNickname(state, payload) {
				state.SelectedCustomerNickname = payload
			},
			mutateSelectedCustomerTitle(state, payload) {
				state.SelectedCustomerTitle = payload
			},
			mutateShowChangeCustomerBtn(state, payload) {
				state.ShowChangeCustomerBtn = payload
			},
			mutateOrderAddOrEdit(state, payload) {
				state.OrderAddOrEdit = payload
			},
			mutateOrderDate(state, payload) {
				state.OrderDate = payload
			},
			mutateOrderNumber(state, payload) {
				state.OrderNumber = payload
			},
			mutateOrderType(state, payload) {
				state.OrderType = payload
			},
			mutateOrderLanguage(state, payload) {
				state.OrderLanguage = payload
			},
			mutateOrderExchangeUnit(state, payload) {
				state.OrderExchangeUnit = payload
			},
			mutateOrderExchangeRate(state, payload) {
				state.OrderExchangeRate = payload
			},
			mutateOrderAddEditToggle(state, payload) {
				state.OrderAddEditToggle = payload
			},
			mutateKeySetOrderHeadersDetails(state, payload) {
				state.KeySetOrderHeadersDetails = payload
			},
			mutateShowOrderHeader(state, payload) {
				state.ShowOrderHeader = payload
			},
			mutateShowForm(state, payload) {
				state.ShowForm = payload
			},
			mutateShowAddOrderForm(state, payload) {
				state.ShowAddOrderForm = payload
			},
			mutateShowAddBtn(state, payload) {
				state.ShowAddBtn = payload
			},
			mutateShowOrderLines(state, payload) {
				state.ShowOrderLines = payload
			},
			mutateSelectedStockItem(state, payload) {
				state.SelectedStockItem = payload
			},
			mutateOrderLineInfo(state, payload) {
				state.OrderLineInfo = payload
			},
			mutateShowOrderSelectStock(state, payload) {
				state.ShowOrderSelectStock = payload
			},
			mutateShowFormAddOrderLines(state, payload) {
				state.ShowFormAddOrderLines = payload
			},
			mutateGoodsTransactionUUID(state, payload) {
				state.GoodsTransactionUUID = payload
			},
			mutateShowSaveAndCloseBtn(state, payload) {
				state.ShowSaveAndCloseBtn = payload
			},
			mutateKeyFormAddOrderLines(state, payload) {
				state.KeyFormAddOrderLines = payload
			},
			mutateKeyFormOrderHeaders(state, payload) {
				state.KeyFormOrderHeaders = payload
			},
			mutateShowExchangeButtonOld(state, payload) {
				state.ShowExchangeButtonOld = payload
			},
			mutateShowExchangeFieldsOld(state, payload) {
				state.ShowExchangeFieldsOld = payload
			},
		},
		actions: {
			setKeySelectCustomer({ commit }, payload) {
				commit('mutateKeySelectCustomer', payload)
			},
			setShowSelectSearchForm({ commit }, payload) {
				commit('mutateShowSelectSearchForm', payload)
			},
			setSelectedCustomerID({ commit }, payload) {
				commit('mutateSelectedCustomerID', payload)
			},
			setSelectedCustomerNickname({ commit }, payload) {
				commit('mutateSelectedCustomerNickname', payload)
			},
			setSelectedCustomerTitle({ commit }, payload) {
				commit('mutateSelectedCustomerTitle', payload)
			},
			setShowChangeCustomerBtn({ commit }, payload) {
				commit('mutateShowChangeCustomerBtn', payload)
			},
			setOrderAddOrEdit({ commit }, payload) {
				commit('mutateOrderAddOrEdit', payload)
			},
			setOrderDate({ commit }, payload) {
				commit('mutateOrderDate', payload)
			},
			setOrderNumber({ commit }, payload) {
				commit('mutateOrderNumber', payload)
			},
			setOrderType({ commit }, payload) {
				commit('mutateOrderType', payload)
			},
			setOrderLanguage({ commit }, payload) {
				commit('mutateOrderLanguage', payload)
			},
			setOrderExchangeUnit({ commit }, payload) {
				commit('mutateOrderExchangeUnit', payload)
			},
			setOrderExchangeRate({ commit }, payload) {
				commit('mutateOrderExchangeRate', payload)
			},
			setOrderAddEditToggle({ commit }, payload) {
				commit('mutateOrderAddEditToggle', payload)
			},
			setKeySetOrderHeadersDetails({ commit }, payload) {
				commit('mutateKeySetOrderHeadersDetails', payload)
			},
			setShowOrderHeader({ commit }, payload) {
				commit('mutateShowOrderHeader', payload)
			},
			setShowForm({ commit }, payload) {
				commit('mutateShowForm', payload)
			},
			setShowAddOrderForm({ commit }, payload) {
				commit('mutateShowAddOrderForm', payload)
			},
			setShowAddBtn({ commit }, payload) {
				commit('mutateShowAddBtn', payload)
			},
			setShowOrderLines({ commit }, payload) {
				commit('mutateShowOrderLines', payload)
			},
			setSelectedStockItem({ commit }, payload) {
				commit('mutateSelectedStockItem', payload)
			},
			setOrderLineInfo({ commit }, payload) {
				commit('mutateOrderLineInfo', payload)
			},
			setShowOrderSelectStock({ commit }, payload) {
				commit('mutateShowOrderSelectStock', payload)
			},
			setShowFormAddOrderLines({ commit }, payload) {
				commit('mutateShowFormAddOrderLines', payload)
			},
			setGoodsTransactionUUID({ commit }, payload) {
				commit('mutateGoodsTransactionUUID', payload)
			},
			setShowSaveAndCloseBtn({ commit }, payload) {
				commit('mutateShowSaveAndCloseBtn', payload)
			},
			setKeyFormAddOrderLines({ commit }, payload) {
				commit('mutateKeyFormAddOrderLines', payload)
			},
			setKeyFormOrderHeaders({ commit }, payload) {
				commit('mutateKeyFormOrderHeaders', payload)
			},
			setShowExchangeButtonOld({ commit }, payload) {
				commit('mutateShowExchangeButtonOld', payload)
			},
			setShowExchangeFieldsOld({ commit }, payload) {
				commit('mutateShowExchangeFieldsOld', payload)
			},
		},
	}
}
