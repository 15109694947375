export function customer() {
	return {
		state: {
			telephoneAddEditToggle: '',
			customerTelAddress: false,
			customerAddEditToggle: '',
			customerTelephoneAddEditToggle: '',
			// KeyStockReload
			customerReloadKey: 1,
		},
		getters: {
			getTelephoneAddEditToggle: (state) => state.telephoneAddEditToggle,
			getCustomerTelAddress: (state) => state.customerTelAddress,
			getCustomerAddEditToggle: (state) => state.customerAddEditToggle,
			getCustomerTelephoneAddEditToggle: (state) =>
				state.customerTelephoneAddEditToggle,
			getCustomerReloadKey: (state) => state.customerReloadKey,
		},
		mutations: {
			mutateTelephoneAddEditToggle(state, payload) {
				state.telephoneAddEditToggle = payload
			},
			mutateCustomerTelAddress(state, payload) {
				state.customerTelAddress = payload
			},
			mutateCustomerAddEditToggle(state, payload) {
				state.customerAddEditToggle = payload
			},
			mutateCustomerTelephoneAddEditToggle(state, payload) {
				state.customerTelephoneAddEditToggle = payload
			},
			mutateCustomerReloadKey(state, payload) {
				state.customerReloadKey = payload
			},
		},
		actions: {
			setTelephoneAddEditToggle({ commit }, payload) {
				commit('mutateTelephoneAddEditToggle', payload)
			},
			setCustomerTelAddress({ commit }, payload) {
				commit('mutateCustomerTelAddress', payload)
			},
			setCustomerAddEditToggle({ commit }, payload) {
				commit('mutateCustomerAddEditToggle', payload)
			},
			setCustomerTelephoneAddEditToggle({ commit }, payload) {
				commit('mutateCustomerTelephoneAddEditToggle', payload)
			},
			setCustomerReloadKey({ commit }, payload) {
				commit('mutateCustomerReloadKey', payload)
			},
		},
	}
}
