<template>
	<div id="app">
		<div class="container container-app">
			<div class="main-body">
				<NavBar class="navbar-app" />
				<div class="router-view-app">
					<div class="section outer-container">
						<div class="main-container">
							<slot />
						</div>
					</div>
				</div>
				<div class="footer-app">
					<Footer />
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	setup() {},
}
</script>
<style scoped>
html,
body {
	height: 100%;
	margin: 20px;
}

.navbar-app {
	padding: 1em;
	position: fixed;
	top: 2px;
	z-index: 5000;
}

.footer-app {
	position: fixed;
	bottom: 10px;
}

.container-app {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding-bottom: 70px;
}

.main-body {
	flex: 1;
}

.router-view-app {
	padding-top: 6em;
}
</style>
