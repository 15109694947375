<template>
	<div id="app">
		<div class="container containerapp">
			<div class="mainbody">
				<NavBarSimple class="navbarapp" />
				<div class="routerviewapp">
					<slot />
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'LoginLayout',
	mounted() {
		this.$i18n.locale = localStorage.getItem('localei18n')
	},
}
</script>

<style scoped>
html,
body {
	height: 100%;
	margin: 20px;
}

.navbarapp {
	padding: 1em;
	position: fixed;
	top: 2px;
	z-index: 5000;
}

.containerapp {
	display: flex;
	flex-direction: column;
	min-height: 100vh;
	padding-bottom: 70px;
}

.mainbody {
	flex: 1;
}

.routerviewapp {
	padding-top: 6em;
}
</style>
