/** @format */

export function document() {
	return {
		state: {
			PdfReportData: {},
			DisableSaveOrderHeaders: true,
			KeySelectOrderHeadersDetails: 1,
			KeySubSummaryHeaders: 1,
			ShowSubSummaryHeaders: false,
			ShowAddOrderLines: false,
			IsButtonsDisabled: false,
			ErrExcRate: false,
			ErrExcPayment: false,
			ErrPayment: false,
			KeyTable: 1,
			// * transaction select button
			isSelectDisabled: false,
			isSelectThrottled: false,
		},
		getters: {
			getPdfReportData: (state) => state.PdfReportData,
			getDisableSaveOrderHeaders: (state) => state.DisableSaveOrderHeaders,
			getKeySelectOrderHeadersDetails: (state) =>
				state.KeySelectOrderHeadersDetails,
			getKeySubSummaryHeaders: (state) => state.KeySubSummaryHeaders,
			getShowSubSummaryHeaders: (state) => state.ShowSubSummaryHeaders,
			getShowAddOrderLines: (state) => state.ShowAddOrderLines,
			getIsButtonsDisabled: (state) => state.IsButtonsDisabled,
			getErrExcRate: (state) => state.ErrExcRate,
			getErrExcPayment: (state) => state.ErrExcPayment,
			getErrPayment: (state) => state.ErrPayment,
			getKeyTable: (state) => state.KeyTable,
			getIsSelectDisabled: (state) => state.isSelectDisabled,
			getIsSelectThrottled: (state) => state.isSelectThrottled,
		},
		mutations: {
			mutatePdfReportData(state, payload) {
				state.PdfReportData = payload
			},
			mutateDisableSaveOrderHeaders(state, payload) {
				state.DisableSaveOrderHeaders = payload
			},
			mutateKeySelectOrderHeadersDetails(state, payload) {
				state.KeySelectOrderHeadersDetails = payload
			},
			mutateKeySubSummaryHeaders(state, payload) {
				state.KeySubSummaryHeaders = payload
			},
			mutateShowSubSummaryHeaders(state, payload) {
				state.ShowSubSummaryHeaders = payload
			},
			mutateShowAddOrderLines(state, payload) {
				state.ShowAddOrderLines = payload
			},
			mutateIsButtonsDisabled(state, payload) {
				state.IsButtonsDisabled = payload
			},
			mutateErrExcRate(state, payload) {
				state.ErrExcRate = payload
			},
			mutateErrExcPayment(state, payload) {
				state.ErrExcPayment = payload
			},
			mutateErrPayment(state, payload) {
				state.ErrPayment = payload
			},
			mutateKeyTable(state, payload) {
				state.KeyTable = payload
			},
			mutateIsSelectDisabled(state, payload) {
				state.isSelectDisabled = payload
			},
			mutateIsSelectThrottled(state, payload) {
				state.isSelectThrottled = payload
			},
		},
		actions: {
			setPdfReportData({ commit }, payload) {
				commit('mutatePdfReportData', payload)
			},
			setDisableSaveOrderHeaders({ commit }, payload) {
				commit('mutateDisableSaveOrderHeaders', payload)
			},
			setKeySelectOrderHeadersDetails({ commit }, payload) {
				commit('mutateKeySelectOrderHeadersDetails', payload)
			},
			setKeySubSummaryHeaders({ commit }, payload) {
				commit('mutateKeySubSummaryHeaders', payload)
			},
			setShowSubSummaryHeaders({ commit }, payload) {
				commit('mutateShowSubSummaryHeaders', payload)
			},
			setShowAddOrderLines({ commit }, payload) {
				commit('mutateShowAddOrderLines', payload)
			},
			setIsButtonsDisabled({ commit }, payload) {
				commit('mutateIsButtonsDisabled', payload)
			},
			setErrExcRate({ commit }, payload) {
				commit('mutateErrExcRate', payload)
			},
			setErrExcPayment({ commit }, payload) {
				commit('mutateErrExcPayment', payload)
			},
			setErrPayment({ commit }, payload) {
				commit('mutateErrPayment', payload)
			},
			setKeyTable({ commit }, payload) {
				commit('mutateKeyTable', payload)
			},
			setIsSelectDisabled({ commit }, payload) {
				commit('mutateIsSelectDisabled', payload)
			},
			setIsSelectThrottled({ commit }, payload) {
				commit('mutateIsSelectThrottled', payload)
			},
		},
	}
}
