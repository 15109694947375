export function stock() {
	return {
		state: {
			keyStockReload: 1,
			stockVatDropDown: ['vat'],
			stockUnitDropDown: ['unit'],
			stockGroupDropDown: ['group'],
			stockItemUrl: 'nolink',
			stockImageDes: false,
			stockCurrentId: '',
			stockCurrentDescription: '',
			stockIsActive: true,
			stockDesToggle: true,
			stockDesBtnToggle: true,
			showSearchForm: false,
			stockAddEditToggle: '',
		},
		getters: {
			getKeyStockReload: (state) => state.keyStockReload,
			getStockVatDropDown: (state) => state.stockVatDropDown,
			getStockUnitDropDown: (state) => state.stockUnitDropDown,
			getStockGroupDropDown: (state) => state.stockGroupDropDown,
			getStockItemUrl: (state) => state.stockItemUrl,
			getStockImageDes: (state) => state.stockImageDes,
			getStockCurrentId: (state) => state.stockCurrentId,
			getStockCurrentDescription: (state) => state.stockCurrentDescription,
			getStockIsActive: (state) => state.stockIsActive,
			getStockDesBtnToggle: (state) => state.stockDesBtnToggle,
			getShowSearchForm: (state) => state.showSearchForm,
			getStockAddEditToggle: (state) => state.stockAddEditToggle,
		},
		mutations: {
			mutateKeyStockReload(state, payload) {
				state.keyStockReload = payload
			},
			mutateStockVatDropDown(state, payload) {
				state.stockVatDropDown = payload
			},
			mutateStockUnitDropDown(state, payload) {
				state.stockUnitDropDown = payload
			},
			mutateStockGroupDropDown(state, payload) {
				state.stockGroupDropDown = payload
			},
			mutateStockItemUrl(state, payload) {
				state.stockItemUrl = payload
			},
			mutateStockImageDes(state, payload) {
				state.stockImageDes = payload
			},
			mutateStockCurrentId(state, payload) {
				state.stockCurrentId = payload
			},
			mutateStockCurrentDescription(state, payload) {
				state.stockCurrentDescription = payload
			},
			mutateStockIsActive(state, payload) {
				state.stockIsActive = payload
			},
			mutateStockDesBtnToggle(state, payload) {
				state.stockDesBtnToggle = payload
			},
			mutateShowSearchForm(state, payload) {
				state.showSearchForm = payload
			},
			mutateStockAddEditToggle(state, payload) {
				state.stockAddEditToggle = payload
			},
		},
		actions: {
			setKeyStockReload({ commit }, payload) {
				commit('mutateKeyStockReload', payload)
			},
			setStockVatDropDown({ commit }, payload) {
				commit('mutateStockVatDropDown', payload)
			},
			setStockUnitDropDown({ commit }, payload) {
				commit('mutateStockUnitDropDown', payload)
			},
			setStockGroupDropDown({ commit }, payload) {
				commit('mutateStockGroupDropDown', payload)
			},
			setStockItemUrl({ commit }, payload) {
				commit('mutateStockItemUrl', payload)
			},
			setStockImageDes({ commit }, payload) {
				commit('mutateStockImageDes', payload)
			},
			setStockCurrentId({ commit }, payload) {
				commit('mutateStockCurrentId', payload)
			},
			setStockCurrentDescription({ commit }, payload) {
				commit('mutateStockCurrentDescription', payload)
			},
			setStockIsActive({ commit }, payload) {
				commit('mutateStockIsActive', payload)
			},
			setStockDesBtnToggle({ commit }, payload) {
				commit('mutateStockDesBtnToggle', payload)
			},
			setShowSearchForm({ commit }, payload) {
				commit('mutateShowSearchForm', payload)
			},
			setStockAddEditToggle({ commit }, payload) {
				commit('mutateStockAddEditToggle', payload)
			},
		},
	}
}
