/** @format */

export function routeGuards() {
	return {
		// namespaced: true,//
		state: {
			stockGuard: '',
			companiesGuard: '',
			orderGuard: '',
			oldOrderGuard: '',
			oldDispatchGuard: '',
			dispatchGuard: '',
			invoiceGuard: '',
			saleInvoiceGuard: '',
			purchaseInvoiceGuard: '',
			reminderGuard: '',
			transactionsGuard: '',
			accountingGuard: '',
			marketingGuard: '',
			adminGuard: '',
			devGuard: '',
			utilitiesGuard: '',
			accountantGuard: '',
		},
		getters: {
			getStockGuard: (state) => state.stockGuard,
			getCompaniesGuard: (state) => state.companiesGuard,
			getOrderGuard: (state) => state.orderGuard,
			getOldOrderGuard: (state) => state.oldOrderGuard,
			getOldDispatchGuard: (state) => state.oldDispatchGuard,
			getDispatchGuard: (state) => state.dispatchGuard,
			getInvoiceGuard: (state) => state.invoiceGuard,
			getSaleInvoiceGuard: (state) => state.saleInvoiceGuard,
			getPurchaseInvoiceGuard: (state) => state.purchaseInvoiceGuard,
			getReminderGuard: (state) => state.reminderGuard,
			getAccountingGuard: (state) => state.accountingGuard,
			getMarketingGuard: (state) => state.marketingGuard,
			getAdminGuard: (state) => state.adminGuard,
			getDevGuard: (state) => state.devGuard,
			getTransactionsGuard: (state) => state.transactionsGuard,
			getUtilitiesGuard: (state) => state.utilitiesGuard,
			getAccountantGuard: (state) => state.accountantGuard,
		},
		mutations: {
			mutateStockGuard(state, payload) {
				state.stockGuard = payload
			},
			mutateCompaniesGuard(state, payload) {
				state.companiesGuard = payload
			},
			mutateOrderGuard(state, payload) {
				state.orderGuard = payload
			},
			mutateOldOrderGuard(state, payload) {
				state.oldOrderGuard = payload
			},
			mutateOldDispatchGuard(state, payload) {
				state.oldDispatchGuard = payload
			},
			mutateDispatchGuard(state, payload) {
				state.dispatchGuard = payload
			},
			mutateInvoiceGuard(state, payload) {
				state.invoiceGuard = payload
			},
			mutateSaleInvoiceGuard(state, payload) {
				state.saleInvoiceGuard = payload
			},
			mutatePurchaseInvoiceGuard(state, payload) {
				state.purchaseInvoiceGuard = payload
			},
			mutateReminderGuard(state, payload) {
				state.reminderGuard = payload
			},
			mutateTransactionsGuard(state, payload) {
				state.transactionsGuard = payload
			},
			mutateAccountingGuard(state, payload) {
				state.accountingGuard = payload
			},
			mutateMarketingGuard(state, payload) {
				state.marketingGuard = payload
			},
			mutateAdminGuard(state, payload) {
				state.adminGuard = payload
			},
			mutateDevGuard(state, payload) {
				state.devGuard = payload
			},
			mutateUtilitiesGuard(state, payload) {
				state.utilitiesGuard = payload
			},
			mutateAccountantGuard(state, payload) {
				state.accountantGuard = payload
			},
		},
		actions: {
			setStockGuard({ commit }, payload) {
				commit('mutateStockGuard', payload)
			},
			setCompaniesGuard({ commit }, payload) {
				commit('mutateCompaniesGuard', payload)
			},
			setOrderGuard({ commit }, payload) {
				commit('mutateOrderGuard', payload)
			},
			setOldOrderGuard({ commit }, payload) {
				commit('mutateOldOrderGuard', payload)
			},
			setOldDispatchGuard({ commit }, payload) {
				commit('mutateOldDispatchGuard', payload)
			},
			setDispatchGuard({ commit }, payload) {
				commit('mutateDispatchGuard', payload)
			},
			setInvoiceGuard({ commit }, payload) {
				commit('mutateInvoiceGuard', payload)
			},
			setSaleInvoiceGuard({ commit }, payload) {
				commit('mutateSaleInvoiceGuard', payload)
			},
			setPurchaseInvoiceGuard({ commit }, payload) {
				commit('mutatePurchaseInvoiceGuard', payload)
			},
			setReminderGuard({ commit }, payload) {
				commit('mutateReminderGuard', payload)
			},
			setAccountingGuard({ commit }, payload) {
				commit('mutateAccountingGuard', payload)
			},
			setTransactionsGuard({ commit }, payload) {
				commit('mutateTransactionsGuard', payload)
			},
			setMarketingGuard({ commit }, payload) {
				commit('mutateMarketingGuard', payload)
			},
			setAdminGuard({ commit }, payload) {
				commit('mutateAdminGuard', payload)
			},
			setDevGuard({ commit }, payload) {
				commit('mutateDevGuard', payload)
			},
			setUtilitiesGuard({ commit }, payload) {
				commit('mutateUtilitiesGuard', payload)
			},
			setAccountantGuard({ commit }, payload) {
				commit('mutateAccountantGuard', payload)
			},
		},
	}
}
