/** @format */

import Vue from 'vue'
import LayoutDefault from './LayoutDefault'
import LayoutWide from './LayoutWide'
import LayoutDefaultNew from './LayoutDefaultNew'
import LayoutDefaultMobile from './LayoutDefaultMobile'
import LayoutLogin from './LayoutLogin'

Vue.component('LayoutDefault', LayoutDefault)
Vue.component('LayoutWide', LayoutWide)
Vue.component('LayoutDefaultNew', LayoutDefaultNew)
Vue.component('LayoutDefaultMobile', LayoutDefaultMobile)
Vue.component('LayoutLogin', LayoutLogin)
