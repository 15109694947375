/** @format */

// Get the date for document
import dateformat from 'dateformat'

const getDate = () => {
	let documentDateFromSystem = new Date()
	return dateformat(documentDateFromSystem, 'yyyy-mm-dd')
}

export function storeDocument() {
	return {
		state: {
			// Document Headers
			DocumentHeadersData: {},
			// CreateNewDocumentLayout
			SearchType: '',
			DocumentType: '',
			AddEditType: '',
			ShowDocumentHeaders: false,
			ShowGetNewDocumentNumber: false,
			StateGetNewDocumentNumber: true,
			ShowAddEditForm: false,
			ShowAddEditPurchaseInvoiceForm: false,
			KeyDocumentHeaders: 1,
			KeyGetNewDocumentNumber: 1,
			KeyAddEditForm: 1,
			SelectedDispatchVariables: {},
			SelectedInvoiceVariables: {},
			// Document variables
			DocumentCompanyID: '',
			DocumentOurCompany: '',
			DocumentDate: getDate(),
			DocumentDateCorrection: '',
			DocumentDueDate: '',
			DocumentNumber: '',
			// useAddEditOrderState
			ShowExchangeButton: true,
			ShowExchangeFields: false,
			// GetCompanyAddressesButtons
			ShowSelectDispatchButton: true,
			ShowSelectInvoiceButton: true,
			ShowSelectReminderButton: true,
			ShowGetCompanyAddressesForm: false,
			AddressTypeToSelect: '',
			KeyGetCompanyAddressesForm: 1,
			// GetDispatchAddress
			ShowDispatchAddress: false,
			DispatchAddressId: '',
			DispatchAddress: {},
			KeyDispatchAddress: 1,
			// GetInvoiceAddress
			ShowInvoiceAddress: false,
			InvoiceAddressId: '',
			InvoiceAddress: {},
			KeyInvoiceAddress: 1,
			// GetReminderAddress
			ShowReminderAddress: false,
			ReminderAddressId: '',
			ReminderAddress: {},
			KeyReminderAddress: 1,
			// Invoice result list
			KeySearchInvoiceResultList: 1,
			// Search Dispatches to Invoice Result List
			DispatchesDailyMonthlyToggle: '',
			// Reports
			ReminderReportData: {},
			KeyReminderReport: 1,
			GetDocumentById: false,
			OrderReportData: {},
			DispatchReportData: {},
			InvoiceReportData: {},
			PriceListReportData: {},
			SelectedPriceListReportData: {},
			StockGroupsForReport: {},
			PriceListType: '',
			KeyOrderReport: 1,
			KeyDispatchReport: 1,
			KeyInvoiceReport: 1,
			KeySearchDispatchesToInvoiceResultList: 1,
			ShowPriceListLoadingMessage: true,
			// Current Document (Order, Dispatch, Invoice, Reminder) Header Fields
			CurrentOrderHeaders: {},
			CurrentDispatchHeaders: {},
			CurrentInvoiceHeaders: {},
			CurrentReminderHeaders: {},
			CurrentCustomerDetails: {},
			// Invoice Type
			CreditTag: '',
			// Accounting variables
			TransactionDate: '',
			KeyTransactionForm: 1,
			KeyTransactionMainLayoutSearchBox: 1,
			PaymentAccountingTypeDropBox: [],
			SelectableDebitAccounts: [],
			SelectableCreditAccounts: [],
			// Admin Report variables
			SelectedCustomerId: '',
			SelectedCustomerInfo: '',
			// transactions
			IsInvoicePaid: '',
			PaymentCredit: 0,
			PaymentCreditExchange: 0,
		},
		getters: {
			getDocumentHeadersData: (state) => state.DocumentHeadersData,
			getSearchType: (state) => state.SearchType,
			getDocumentType: (state) => state.DocumentType,
			getAddEditType: (state) => state.AddEditType,
			getShowDocumentHeaders: (state) => state.ShowDocumentHeaders,
			getShowGetNewDocumentNumber: (state) => state.ShowGetNewDocumentNumber,
			getStateGetNewDocumentNumber: (state) => state.StateGetNewDocumentNumber,
			getShowAddEditForm: (state) => state.ShowAddEditForm,
			getShowAddEditPurchaseInvoiceForm: (state) =>
				state.ShowAddEditPurchaseInvoiceForm,
			getKeyDocumentHeaders: (state) => state.KeyDocumentHeaders,
			getKeyGetNewDocumentNumber: (state) => state.KeyGetNewDocumentNumber,
			getKeyAddEditForm: (state) => state.KeyAddEditForm,
			getSelectedDispatchVariables: (state) => state.SelectedDispatchVariables,
			getSelectedInvoiceVariables: (state) => state.SelectedInvoiceVariables,
			getDocumentOurCompany: (state) => state.DocumentOurCompany,
			getDocumentCompanyID: (state) => state.DocumentCompanyID,
			getDocumentDate: (state) => state.DocumentDate,
			getDocumentDateCorrection: (state) => state.DocumentDateCorrection,
			getDocumentDueDate: (state) => state.DocumentDueDate,
			getDocumentNumber: (state) => state.DocumentNumber,
			getShowExchangeButton: (state) => state.ShowExchangeButton,
			getShowExchangeFields: (state) => state.ShowExchangeFields,
			getShowSelectDispatchButton: (state) => state.ShowSelectDispatchButton,
			getShowSelectInvoiceButton: (state) => state.ShowSelectInvoiceButton,
			getShowSelectReminderButton: (state) => state.ShowSelectReminderButton,
			getShowGetCompanyAddressesForm: (state) =>
				state.ShowGetCompanyAddressesForm,
			getAddressTypeToSelect: (state) => state.AddressTypeToSelect,
			getKeyGetCompanyAddressesForm: (state) =>
				state.KeyGetCompanyAddressesForm,
			getShowDispatchAddress: (state) => state.ShowDispatchAddress,
			getDispatchAddressId: (state) => state.DispatchAddressId,
			getDispatchAddress: (state) => state.DispatchAddress,
			getKeyDispatchAddress: (state) => state.KeyDispatchAddress,
			getShowInvoiceAddress: (state) => state.ShowInvoiceAddress,
			getInvoiceAddressId: (state) => state.InvoiceAddressId,
			getInvoiceAddress: (state) => state.InvoiceAddress,
			getKeyInvoiceAddress: (state) => state.KeyInvoiceAddress,
			getShowReminderAddress: (state) => state.ShowReminderAddress,
			getReminderAddressId: (state) => state.ReminderAddressId,
			getReminderAddress: (state) => state.ReminderAddress,
			getKeyReminderAddress: (state) => state.KeyReminderAddress,
			getKeySearchInvoiceResultList: (state) =>
				state.KeySearchInvoiceResultList,
			getDispatchesDailyMonthlyToggle: (state) =>
				state.DispatchesDailyMonthlyToggle,
			getReminderReportData: (state) => state.ReminderReportData,
			getKeyReminderReport: (state) => state.KeyReminderReport,
			getGetDocumentById: (state) => state.GetDocumentById,
			getOrderReportData: (state) => state.OrderReportData,
			getDispatchReportData: (state) => state.DispatchReportData,
			getInvoiceReportData: (state) => state.InvoiceReportData,
			getPriceListReportData: (state) => state.PriceListReportData,
			getSelectedPriceListReportData: (state) =>
				state.SelectedPriceListReportData,
			getStockGroupsForReport: (state) => state.StockGroupsForReport,
			getPriceListType: (state) => state.PriceListType,
			getKeyOrderReport: (state) => state.KeyOrderReport,
			getKeyDispatchReport: (state) => state.KeyDispatchReport,
			getKeyInvoiceReport: (state) => state.KeyInvoiceReport,
			getKeySearchDispatchesToInvoiceResultList: (state) =>
				state.KeySearchDispatchesToInvoiceResultList,
			getShowPriceListLoadingMessage: (state) =>
				state.ShowPriceListLoadingMessage,
			getCurrentOrderHeaders: (state) => state.CurrentOrderHeaders,
			getCurrentDispatchHeaders: (state) => state.CurrentDispatchHeaders,
			getCurrentInvoiceHeaders: (state) => state.CurrentInvoiceHeaders,
			getCurrentReminderHeaders: (state) => state.CurrentReminderHeaders,
			getCurrentCustomerDetails: (state) => state.CurrentCustomerDetails,
			getCreditTag: (state) => state.CreditTag,
			getTransactionDate: (state) => state.TransactionDate,
			getKeyTransactionForm: (state) => state.KeyTransactionForm,
			getKeyTransactionMainLayoutSearchBox: (state) =>
				state.KeyTransactionMainLayoutSearchBox,
			getPaymentAccountingTypeDropBox: (state) =>
				state.PaymentAccountingTypeDropBox,
			getSelectableDebitAccounts: (state) => state.SelectableDebitAccounts,
			getSelectableCreditAccounts: (state) => state.SelectableCreditAccounts,
			getSelectedCustomerId: (state) => state.SelectedCustomerId,
			getSelectedCustomerInfo: (state) => state.SelectedCustomerInfo,
			// transactions
			getIsInvoicePaid: (state) => state.IsInvoicePaid,
			getPaymentCredit: (state) => state.PaymentCredit,
			getPaymentCreditExchange: (state) => state.PaymentCreditExchange,
		},
		mutations: {
			mutateDocumentHeadersData(state, payload) {
				state.DocumentHeadersData = payload
			},
			mutateSearchType(state, payload) {
				state.SearchType = payload
			},
			mutateDocumentType(state, payload) {
				state.DocumentType = payload
			},
			mutateAddEditType(state, payload) {
				state.AddEditType = payload
			},
			mutateShowDocumentHeaders(state, payload) {
				state.ShowDocumentHeaders = payload
			},
			mutateShowGetNewDocumentNumber(state, payload) {
				state.ShowGetNewDocumentNumber = payload
			},
			mutateStateGetNewDocumentNumber(state, payload) {
				state.StateGetNewDocumentNumber = payload
			},
			mutateShowAddEditForm(state, payload) {
				state.ShowAddEditForm = payload
			},
			mutateShowAddEditPurchaseInvoiceForm(state, payload) {
				state.ShowAddEditPurchaseInvoiceForm = payload
			},
			mutateKeyDocumentHeaders(state, payload) {
				state.KeyDocumentHeaders = payload
			},
			mutateKeyGetNewDocumentNumber(state, payload) {
				state.KeyGetNewDocumentNumber = payload
			},
			mutateKeyAddEditForm(state, payload) {
				state.KeyAddEditForm = payload
			},
			mutateSelectedDispatchVariables(state, payload) {
				state.SelectedDispatchVariables = payload
			},
			mutateSelectedInvoiceVariables(state, payload) {
				state.SelectedInvoiceVariables = payload
			},
			mutateDocumentOurCompany(state, payload) {
				state.DocumentOurCompany = payload
			},
			mutateDocumentCompanyID(state, payload) {
				state.DocumentCompanyID = payload
			},
			mutateDocumentDate(state, payload) {
				state.DocumentDate = payload
			},
			mutateDocumentDateCorrection(state, payload) {
				state.DocumentDateCorrection = payload
			},
			mutateDocumentDueDate(state, payload) {
				state.DocumentDueDate = payload
			},
			mutateDocumentNumber(state, payload) {
				state.DocumentNumber = payload
			},
			mutateShowExchangeButton(state, payload) {
				state.ShowExchangeButton = payload
			},
			mutateShowExchangeFields(state, payload) {
				state.ShowExchangeFields = payload
			},
			mutateShowSelectDispatchButton(state, payload) {
				state.ShowSelectDispatchButton = payload
			},
			mutateShowSelectInvoiceButton(state, payload) {
				state.ShowSelectInvoiceButton = payload
			},
			mutateShowSelectReminderButton(state, payload) {
				state.ShowSelectReminderButton = payload
			},
			mutateShowGetCompanyAddressesForm(state, payload) {
				state.ShowGetCompanyAddressesForm = payload
			},
			mutateAddressTypeToSelect(state, payload) {
				state.AddressTypeToSelect = payload
			},
			mutateKeyGetCompanyAddressesForm(state, payload) {
				state.KeyGetCompanyAddressesForm = payload
			},
			mutateShowDispatchAddress(state, payload) {
				state.ShowDispatchAddress = payload
			},
			mutateDispatchAddressId(state, payload) {
				state.DispatchAddressId = payload
			},
			mutateDispatchAddress(state, payload) {
				state.DispatchAddress = payload
			},
			mutateKeyDispatchAddress(state, payload) {
				state.KeyDispatchAddress = payload
			},
			mutateShowInvoiceAddress(state, payload) {
				state.ShowInvoiceAddress = payload
			},
			mutateInvoiceAddressId(state, payload) {
				state.InvoiceAddressId = payload
			},
			mutateInvoiceAddress(state, payload) {
				state.InvoiceAddress = payload
			},
			mutateKeyInvoiceAddress(state, payload) {
				state.KeyInvoiceAddress = payload
			},
			mutateShowReminderAddress(state, payload) {
				state.ShowReminderAddress = payload
			},
			mutateReminderAddressId(state, payload) {
				state.ReminderAddressId = payload
			},
			mutateReminderAddress(state, payload) {
				state.ReminderAddress = payload
			},
			mutateKeyReminderAddress(state, payload) {
				state.KeyReminderAddress = payload
			},
			mutateKeySearchInvoiceResultList(state, payload) {
				state.KeySearchInvoiceResultList = payload
			},
			mutateDispatchesDailyMonthlyToggle(state, payload) {
				state.DispatchesDailyMonthlyToggle = payload
			},
			mutateReminderReportData(state, payload) {
				state.ReminderReportData = payload
			},
			mutateKeyReminderReport(state, payload) {
				state.KeyReminderReport = payload
			},
			mutateGetDocumentById(state, payload) {
				state.GetDocumentById = payload
			},
			mutateOrderReportData(state, payload) {
				state.OrderReportData = payload
			},
			mutateDispatchReportData(state, payload) {
				state.DispatchReportData = payload
			},
			mutateInvoiceReportData(state, payload) {
				state.InvoiceReportData = payload
			},
			mutatePriceListReportData(state, payload) {
				state.PriceListReportData = payload
			},
			mutateSelectedPriceListReportData(state, payload) {
				state.SelectedPriceListReportData = payload
			},
			mutateStockGroupsForReport(state, payload) {
				state.StockGroupsForReport = payload
			},
			mutatePriceListType(state, payload) {
				state.PriceListType = payload
			},
			mutateKeyOrderReport(state, payload) {
				state.KeyOrderReport = payload
			},
			mutateKeyDispatchReport(state, payload) {
				state.KeyDispatchReport = payload
			},
			mutateKeyInvoiceReport(state, payload) {
				state.KeyInvoiceReport = payload
			},
			mutateKeySearchDispatchesToInvoiceResultList(state, payload) {
				state.KeySearchDispatchesToInvoiceResultList = payload
			},
			mutateShowPriceListLoadingMessage(state, payload) {
				state.ShowPriceListLoadingMessage = payload
			},
			mutateCurrentOrderHeaders(state, payload) {
				state.CurrentOrderHeaders = payload
			},
			mutateCurrentDispatchHeaders(state, payload) {
				state.CurrentDispatchHeaders = payload
			},
			mutateCurrentInvoiceHeaders(state, payload) {
				state.CurrentInvoiceHeaders = payload
			},
			mutateCurrentReminderHeaders(state, payload) {
				state.CurrentReminderHeaders = payload
			},
			mutateCurrentCustomerDetails(state, payload) {
				state.CurrentCustomerDetails = payload
			},
			mutateCreditTag(state, payload) {
				state.CreditTag = payload
			},
			mutateTransactionDate(state, payload) {
				state.TransactionDate = payload
			},
			mutateKeyTransactionForm(state, payload) {
				state.KeyTransactionForm = payload
			},
			mutateKeyTransactionMainLayoutSearchBox(state, payload) {
				state.KeyTransactionMainLayoutSearchBox = payload
			},
			mutatePaymentAccountingTypeDropBox(state, payload) {
				state.PaymentAccountingTypeDropBox = payload
			},
			mutateSelectableDebitAccounts(state, payload) {
				state.SelectableDebitAccounts = payload
			},
			mutateSelectableCreditAccounts(state, payload) {
				state.SelectableCreditAccounts = payload
			},
			mutateSelectedCustomerId(state, payload) {
				state.SelectedCustomerId = payload
			},
			mutateSelectedCustomerInfo(state, payload) {
				state.SelectedCustomerInfo = payload
			},
			// transactions
			mutateIsInvoicePaid(state, payload) {
				state.IsInvoicePaid = payload
			},
			mutatePaymentCredit(state, payload) {
				state.PaymentCredit = payload
			},
			mutatePaymentCreditExchange(state, payload) {
				state.PaymentCreditExchange = payload
			},
		},
		actions: {
			setDocumentHeadersData({ commit }, payload) {
				commit('mutateDocumentHeadersData', payload)
			},
			setSearchType({ commit }, payload) {
				commit('mutateSearchType', payload)
			},
			setDocumentType({ commit }, payload) {
				commit('mutateDocumentType', payload)
			},
			setAddEditType({ commit }, payload) {
				commit('mutateAddEditType', payload)
			},
			setShowDocumentHeaders({ commit }, payload) {
				commit('mutateShowDocumentHeaders', payload)
			},
			setShowGetNewDocumentNumber({ commit }, payload) {
				commit('mutateShowGetNewDocumentNumber', payload)
			},
			setStateGetNewDocumentNumber({ commit }, payload) {
				commit('mutateStateGetNewDocumentNumber', payload)
			},
			setShowAddEditForm({ commit }, payload) {
				commit('mutateShowAddEditForm', payload)
			},
			setShowAddEditPurchaseInvoiceForm({ commit }, payload) {
				commit('mutateShowAddEditPurchaseInvoiceForm', payload)
			},
			setKeyDocumentHeaders({ commit }, payload) {
				commit('mutateKeyDocumentHeaders', payload)
			},
			setKeyGetNewDocumentNumber({ commit }, payload) {
				commit('mutateKeyGetNewDocumentNumber', payload)
			},
			setKeyAddEditForm({ commit }, payload) {
				commit('mutateKeyAddEditForm', payload)
			},
			setSelectedDispatchVariables({ commit }, payload) {
				commit('mutateSelectedDispatchVariables', payload)
			},
			setSelectedInvoiceVariables({ commit }, payload) {
				commit('mutateSelectedInvoiceVariables', payload)
			},
			setDocumentOurCompany({ commit }, payload) {
				commit('mutateDocumentOurCompany', payload)
			},
			setDocumentCompanyID({ commit }, payload) {
				commit('mutateDocumentCompanyID', payload)
			},
			setDocumentDate({ commit }, payload) {
				commit('mutateDocumentDate', payload)
			},
			setDocumentDateCorrection({ commit }, payload) {
				commit('mutateDocumentDateCorrection', payload)
			},
			setDocumentDueDate({ commit }, payload) {
				commit('mutateDocumentDueDate', payload)
			},
			setDocumentNumber({ commit }, payload) {
				commit('mutateDocumentNumber', payload)
			},
			setShowExchangeButton({ commit }, payload) {
				commit('mutateShowExchangeButton', payload)
			},
			setShowExchangeFields({ commit }, payload) {
				commit('mutateShowExchangeFields', payload)
			},
			setShowSelectDispatchButton({ commit }, payload) {
				commit('mutateShowSelectDispatchButton', payload)
			},
			setShowSelectInvoiceButton({ commit }, payload) {
				commit('mutateShowSelectInvoiceButton', payload)
			},
			setShowSelectReminderButton({ commit }, payload) {
				commit('mutateShowSelectReminderButton', payload)
			},
			setShowGetCompanyAddressesForm({ commit }, payload) {
				commit('mutateShowGetCompanyAddressesForm', payload)
			},
			setAddressTypeToSelect({ commit }, payload) {
				commit('mutateAddressTypeToSelect', payload)
			},
			setKeyGetCompanyAddressesForm({ commit }, payload) {
				commit('mutateKeyGetCompanyAddressesForm', payload)
			},
			setShowDispatchAddress({ commit }, payload) {
				commit('mutateShowDispatchAddress', payload)
			},
			setDispatchAddressId({ commit }, payload) {
				commit('mutateDispatchAddressId', payload)
			},
			setDispatchAddress({ commit }, payload) {
				commit('mutateDispatchAddress', payload)
			},
			setKeyDispatchAddress({ commit }, payload) {
				commit('mutateKeyDispatchAddress', payload)
			},
			setShowInvoiceAddress({ commit }, payload) {
				commit('mutateShowInvoiceAddress', payload)
			},
			setInvoiceAddressId({ commit }, payload) {
				commit('mutateInvoiceAddressId', payload)
			},
			setInvoiceAddress({ commit }, payload) {
				commit('mutateInvoiceAddress', payload)
			},
			setKeyInvoiceAddress({ commit }, payload) {
				commit('mutateKeyInvoiceAddress', payload)
			},
			setShowReminderAddress({ commit }, payload) {
				commit('mutateShowReminderAddress', payload)
			},
			setReminderAddressId({ commit }, payload) {
				commit('mutateReminderAddressId', payload)
			},
			setReminderAddress({ commit }, payload) {
				commit('mutateReminderAddress', payload)
			},
			setKeyReminderAddress({ commit }, payload) {
				commit('mutateKeyReminderAddress', payload)
			},
			setKeySearchInvoiceResultList({ commit }, payload) {
				commit('mutateKeySearchInvoiceResultList', payload)
			},
			setDispatchesDailyMonthlyToggle({ commit }, payload) {
				commit('mutateDispatchesDailyMonthlyToggle', payload)
			},
			setReminderReportData({ commit }, payload) {
				commit('mutateReminderReportData', payload)
			},
			setKeyReminderReport({ commit }, payload) {
				commit('mutateKeyReminderReport', payload)
			},
			setGetDocumentById({ commit }, payload) {
				commit('mutateGetDocumentById', payload)
			},
			setOrderReportData({ commit }, payload) {
				commit('mutateOrderReportData', payload)
			},
			setDispatchReportData({ commit }, payload) {
				commit('mutateDispatchReportData', payload)
			},
			setInvoiceReportData({ commit }, payload) {
				commit('mutateInvoiceReportData', payload)
			},
			setPriceListReportData({ commit }, payload) {
				commit('mutatePriceListReportData', payload)
			},
			setSelectedPriceListReportData({ commit }, payload) {
				commit('mutateSelectedPriceListReportData', payload)
			},
			setStockGroupsForReport({ commit }, payload) {
				commit('mutateStockGroupsForReport', payload)
			},
			setPriceListType({ commit }, payload) {
				commit('mutatePriceListType', payload)
			},
			setKeyOrderReport({ commit }, payload) {
				commit('mutateKeyOrderReport', payload)
			},
			setKeyDispatchReport({ commit }, payload) {
				commit('mutateKeyDispatchReport', payload)
			},
			setKeyInvoiceReport({ commit }, payload) {
				commit('mutateKeyInvoiceReport', payload)
			},
			setKeySearchDispatchesToInvoiceResultList({ commit }, payload) {
				commit('mutateKeySearchDispatchesToInvoiceResultList', payload)
			},
			setShowPriceListLoadingMessage({ commit }, payload) {
				commit('mutateShowPriceListLoadingMessage', payload)
			},
			setCurrentOrderHeaders({ commit }, payload) {
				commit('mutateCurrentOrderHeaders', payload)
			},
			setCurrentDispatchHeaders({ commit }, payload) {
				commit('mutateCurrentDispatchHeaders', payload)
			},
			setCurrentInvoiceHeaders({ commit }, payload) {
				commit('mutateCurrentInvoiceHeaders', payload)
			},
			setCurrentReminderHeaders({ commit }, payload) {
				commit('mutateCurrentReminderHeaders', payload)
			},
			setCurrentCustomerDetails({ commit }, payload) {
				commit('mutateCurrentCustomerDetails', payload)
			},
			setCreditTag({ commit }, payload) {
				commit('mutateCreditTag', payload)
			},
			setTransactionDate({ commit }, payload) {
				commit('mutateTransactionDate', payload)
			},
			setKeyTransactionForm({ commit }, payload) {
				commit('mutateKeyTransactionForm', payload)
			},
			setKeyTransactionMainLayoutSearchBox({ commit }, payload) {
				commit('mutateKeyTransactionMainLayoutSearchBox', payload)
			},
			setPaymentAccountingTypeDropBox({ commit }, payload) {
				commit('mutatePaymentAccountingTypeDropBox', payload)
			},
			setSelectableDebitAccounts({ commit }, payload) {
				commit('mutateSelectableDebitAccounts', payload)
			},
			setSelectableCreditAccounts({ commit }, payload) {
				commit('mutateSelectableCreditAccounts', payload)
			},
			setSelectedCustomerId({ commit }, payload) {
				commit('mutateSelectedCustomerId', payload)
			},
			setIsInvoicePaid({ commit }, payload) {
				commit('mutateIsInvoicePaid', payload)
			},
			setPaymentCredit({ commit }, payload) {
				commit('mutatePaymentCredit', payload)
			},
			setPaymentCreditExchange({ commit }, payload) {
				commit('mutatePaymentCreditExchange', payload)
			},
		},
	}
}
