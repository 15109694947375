export function storeDocumentSelectedCompany() {
	return {
		state: {
			// namespace: Dsc
			// Selected Company
			DscOurCompanyNickname: '',
			DscCustomerID: '',
			DscCustomerNickname: '',
			DscCustomerTitle: '',
			DscCustomerOrgNum: '',
			DscCustomerDiscount: 0,
			DscCustomerEmail: '',
			DscIsCustomerActive: true,
			DscCustomerCreditLimit: 0,
			DscCustomerIsRisky: false,
			DscCustomerReference: '',
			DscCustomerTerm: 10,
			DscCustomerInvoiceRule: 'daily',
			DscCustomerConsumedProducts: '',
			DscCustomerLog: '',
			DscSendInvoiceBy: '',
		},
		getters: {
			getDscOurCompanyNickname: (state) => state.DscOurCompanyNickname,
			getDscCustomerID: (state) => state.DscCustomerID,
			getDscCustomerNickname: (state) => state.DscCustomerNickname,
			getDscCustomerTitle: (state) => state.DscCustomerTitle,
			getDscCustomerOrgNum: (state) => state.DscCustomerOrgNum,
			getDscCustomerDiscount: (state) => state.DscCustomerDiscount,
			getDscCustomerEmail: (state) => state.DscCustomerEmail,
			getDscIsCustomerActive: (state) => state.DscIsCustomerActive,
			getDscCustomerCreditLimit: (state) => state.DscCustomerCreditLimit,
			getDscCustomerIsRisky: (state) => state.DscCustomerIsRisky,
			getDscCustomerReference: (state) => state.DscCustomerReference,
			getDscCustomerTerm: (state) => state.DscCustomerTerm,
			getDscCustomerInvoiceRule: (state) => state.DscCustomerInvoiceRule,
			getDscCustomerConsumedProducts: (state) =>
				state.DscCustomerConsumedProducts,
			getDscCustomerLog: (state) => state.DscCustomerLog,
			getDscSendInvoiceBy: (state) => state.DscSendInvoiceBy,
		},
		mutations: {
			mutateDscOurCompanyNickname(state, payload) {
				state.DscOurCompanyNickname = payload
			},
			mutateDscCustomerID(state, payload) {
				state.DscCustomerID = payload
			},
			mutateDscCustomerNickname(state, payload) {
				state.DscCustomerNickname = payload
			},
			mutateDscCustomerTitle(state, payload) {
				state.DscCustomerTitle = payload
			},
			mutateDscCustomerOrgNum(state, payload) {
				state.DscCustomerOrgNum = payload
			},
			mutateDscCustomerDiscount(state, payload) {
				state.DscCustomerDiscount = payload
			},
			mutateDscCustomerEmail(state, payload) {
				state.DscCustomerEmail = payload
			},
			mutateDscIsCustomerActive(state, payload) {
				state.DscIsCustomerActive = payload
			},
			mutateDscCustomerCreditLimit(state, payload) {
				state.DscCustomerCreditLimit = payload
			},
			mutateDscCustomerIsRisky(state, payload) {
				state.DscCustomerIsRisky = payload
			},
			mutateDscCustomerReference(state, payload) {
				state.DscCustomerReference = payload
			},
			mutateDscCustomerTerm(state, payload) {
				state.DscCustomerTerm = payload
			},
			mutateDscCustomerInvoiceRule(state, payload) {
				state.DscCustomerInvoiceRule = payload
			},
			mutateDscCustomerConsumedProducts(state, payload) {
				state.DscCustomerConsumedProducts = payload
			},
			mutateDscCustomerLog(state, payload) {
				state.DscCustomerLog = payload
			},
			mutateDscSendInvoiceBy(state, payload) {
				state.DscSendInvoiceBy = payload
			},
		},
		actions: {
			setDscOurCompanyNickname({ commit }, payload) {
				commit('mutateDscOurCompanyNickname', payload)
			},
			setDscCustomerID({ commit }, payload) {
				commit('mutateDscCustomerID', payload)
			},
			setDscCustomerNickname({ commit }, payload) {
				commit('mutateDscCustomerNickname', payload)
			},
			setDscCustomerTitle({ commit }, payload) {
				commit('mutateDscCustomerTitle', payload)
			},
			setDscCustomerOrgNum({ commit }, payload) {
				commit('mutateDscCustomerOrgNum', payload)
			},
			setDscCustomerDiscount({ commit }, payload) {
				commit('mutateDscCustomerDiscount', payload)
			},
			setDscCustomerEmail({ commit }, payload) {
				commit('mutateDscCustomerEmail', payload)
			},
			setDscIsCustomerActive({ commit }, payload) {
				commit('mutateDscIsCustomerActive', payload)
			},
			setDscCustomerCreditLimit({ commit }, payload) {
				commit('mutateDscCustomerCreditLimit', payload)
			},
			setDscCustomerIsRisky({ commit }, payload) {
				commit('mutateDscCustomerIsRisky', payload)
			},
			setDscCustomerReference({ commit }, payload) {
				commit('mutateDscCustomerReference', payload)
			},
			setDscCustomerTerm({ commit }, payload) {
				commit('mutateDscCustomerTerm', payload)
			},
			setDscCustomerInvoiceRule({ commit }, payload) {
				commit('mutateDscCustomerInvoiceRule', payload)
			},
			setDscCustomerConsumedProducts({ commit }, payload) {
				commit('mutateDscCustomerConsumedProducts', payload)
			},
			setDscCustomerLog({ commit }, payload) {
				commit('mutateDscCustomerLog', payload)
			},
			setDscSendInvoiceBy({ commit }, payload) {
				commit('mutateDscSendInvoiceBy', payload)
			},
		},
	}
}
