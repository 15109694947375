/** @format */

import { lsGetLogInfo } from '@/plugins/lsstore'

export function start() {
	return {
		// namespaced: true,//
		state: {
			today: '',
			dueDate: '',
			locale: '',
			apolloToken: '',
			isLoggedIn: lsGetLogInfo('z}!@li!') || false,
			userName: '',
			userGroups: ['group'],
			userRoles: ['user'],
			userCurrentCompany: '',
			currentCompanyTitle: '',
			appVersion: '0.0.0',
			adminPassKey: '',
			privilegePassKey: '',
			showGetPassDataComponent: '',
		},
		getters: {
			getToday: (state) => state.today,
			getDueDate: (state) => state.dueDate,
			getLocale: (state) => state.locale,
			getApolloToken: (state) => state.apolloToken,
			getIsLoggedIn: (state) => state.isLoggedIn,
			getUserName: (state) => state.userName,
			getUserGroups: (state) => state.userGroups,
			getUserRoles: (state) => state.userRoles,
			getUserCurrentCompany: (state) => state.userCurrentCompany,
			getCurrentCompanyTitle: (state) => state.currentCompanyTitle,
			getAppVersion: (state) => state.appVersion,
			getAdminPassKey: (state) => state.adminPassKey,
			getPrivilegePassKey: (state) => state.privilegePassKey,
			getShowGetPassDataComponent: (state) => state.showGetPassDataComponent,
		},
		mutations: {
			mutateToday(state, payload) {
				state.today = payload
			},
			mutateDueDate(state, payload) {
				state.dueDate = payload
			},
			mutateLocale(state, payload) {
				state.locale = payload
			},
			mutateApolloToken(state, payload) {
				state.apolloToken = payload
			},
			mutateIsLoggedIn(state, payload) {
				state.IsLoggedIn = payload
			},
			mutateUserName(state, payload) {
				state.userName = payload
			},
			mutateUserGroups(state, payload) {
				state.userGroups = payload
			},
			mutateUserRoles(state, payload) {
				state.userRoles = payload
			},
			mutateUserCurrentCompany(state, payload) {
				state.userCurrentCompany = payload
			},
			mutateCurrentCompanyTitle(state, payload) {
				state.currentCompanyTitle = payload
			},
			mutateAppVersion(state, payload) {
				state.AppVersion = payload
			},
			mutateAdminPassKey(state, payload) {
				state.adminPassKey = payload
			},
			mutatePrivilegePassKey(state, payload) {
				state.privilegePassKey = payload
			},
			mutateShowGetPassDataComponent(state, payload) {
				state.showGetPassDataComponent = payload
			},
		},
		actions: {
			setToday({ commit }, payload) {
				commit('mutateToday', payload)
			},
			setDueDate({ commit }, payload) {
				commit('mutateDueDate', payload)
			},
			setLocale({ commit }, payload) {
				commit('mutateLocale', payload)
			},
			setApolloToken({ commit }, payload) {
				commit('mutateApolloToken', payload)
			},
			setIsLoggedIn({ commit }, payload) {
				commit('mutateIsLoggedIn', payload)
			},
			setUserName({ commit }, payload) {
				commit('mutateUserName', payload)
			},
			setUserGroups({ commit }, payload) {
				commit('mutateUserGroups', payload)
			},
			setUserRoles({ commit }, payload) {
				commit('mutateUserRoles', payload)
			},
			setUserCurrentCompany({ commit }, payload) {
				commit('mutateUserCurrentCompany', payload)
			},
			setCurrentCompanyTitle({ commit }, payload) {
				commit('mutateCurrentCompanyTitle', payload)
			},
			setAdminPassKey({ commit }, payload) {
				commit('mutateAdminPassKey', payload)
			},
			setPrivilegePassKey({ commit }, payload) {
				commit('mutatePrivilegePassKey', payload)
			},
			setShowGetPassDataComponent({ commit }, payload) {
				commit('mutateShowGetPassDataComponent', payload)
			},
		},
	}
}
