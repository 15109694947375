export function storeCompanyInfo() {
	return {
		state: {
			CustomerConsumedProducts: '',
			ConsumedProductsToggle: '',
			CustomerLog: '',
			CustomerLogToggle: '',
		},
		getters: {
			getCustomerConsumedProducts: (state) => state.CustomerConsumedProducts,
			getConsumedProductsToggle: (state) => state.ConsumedProductsToggle,
			getCustomerLog: (state) => state.CustomerLog,
			getCustomerLogToggle: (state) => state.CustomerLogToggle,
		},
		mutations: {
			mutateCustomerConsumedProducts(state, payload) {
				state.CustomerConsumedProducts = payload
			},
			mutateConsumedProductsToggle(state, payload) {
				state.ConsumedProductsToggle = payload
			},
			mutateCustomerLog(state, payload) {
				state.CustomerLog = payload
			},
			mutateCustomerLogToggle(state, payload) {
				state.CustomerLogToggle = payload
			},
		},
		actions: {
			setCustomerConsumedProducts({ commit }, payload) {
				commit('mutateCustomerConsumedProducts', payload)
			},
			setConsumedProductsToggle({ commit }, payload) {
				commit('mutateConsumedProductsToggle', payload)
			},
			setCustomerLog({ commit }, payload) {
				commit('mutateCustomerLog', payload)
			},
			setCustomerLogToggle({ commit }, payload) {
				commit('mutateCustomerLogToggle', payload)
			},
		},
	}
}
