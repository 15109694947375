export function invoiceLayout() {
	return {
		state: {
			InvoiceHeaderKey: 1,
			InvoiceCustomersKey: 1,
			InvoiceSelectedCustomerKey: 1,
			InvoiceLinesKey: 1,
			InvoiceLineEditKey: 1,
			InvoiceFooterButtonsKey: 1,
		},
		getters: {
			getInvoiceHeaderKey: (state) => state.InvoiceHeaderKey,
			getInvoiceCustomersKey: (state) => state.InvoiceCustomersKey,
			getInvoiceSelectedCustomerKey: (state) =>
				state.InvoiceSelectedCustomerKey,
			getInvoiceLinesKey: (state) => state.InvoiceLinesKey,
			getInvoiceLineEditKey: (state) => state.InvoiceLineEditKey,
			getInvoiceFooterButtonsKey: (state) => state.InvoiceFooterButtonsKey,
		},
		mutations: {
			mutateInvoiceHeaderKey(state, payload) {
				state.InvoiceHeaderKey = payload
			},
			mutateInvoiceCustomersKey(state, payload) {
				state.InvoiceCustomersKey = payload
			},
			mutateInvoiceSelectedCustomerKey(state, payload) {
				state.InvoiceSelectedCustomerKey = payload
			},
			mutateInvoiceLinesKey(state, payload) {
				state.InvoiceLinesKey = payload
			},
			mutateInvoiceLineEditKey(state, payload) {
				state.InvoiceLineEditKey = payload
			},
			mutateInvoiceFooterButtonsKey(state, payload) {
				state.InvoiceFooterButtonsKey = payload
			},
		},
		actions: {
			setInvoiceHeaderKey({ commit }, payload) {
				commit('mutateInvoiceHeaderKey', payload)
			},
			setInvoiceCustomersKey({ commit }, payload) {
				commit('mutateInvoiceCustomersKey', payload)
			},
			setInvoiceSelectedCustomerKey({ commit }, payload) {
				commit('mutateInvoiceSelectedCustomerKey', payload)
			},
			setInvoiceLinesKey({ commit }, payload) {
				commit('mutateInvoiceLinesKey', payload)
			},
			setInvoiceLineEditKey({ commit }, payload) {
				commit('mutateInvoiceLineEditKey', payload)
			},
			setInvoiceFooterButtonsKey({ commit }, payload) {
				commit('mutateInvoiceFooterButtonsKey', payload)
			},
		},
	}
}
