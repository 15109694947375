<template>
	<div>
		<!-- Two-way Data-Binding -->
		<quill-editor
			ref="myQuillEditor"
			v-model="content"
			:options="editorOption"
			@blur="onEditorBlur($event)"
			@focus="onEditorFocus($event)"
			@ready="onEditorReady($event)"
		/>
		<section>
			<b-button
				class="is-primary margin-top-15"
				v-if="$store.getters.getStockDesBtnToggle"
				@click="setStore"
			>
				Confirm the changes</b-button
			>
		</section>
	</div>
</template>

<script>
import {
	ref,
	// computed,
	// getCurrentInstance
} from '@vue/composition-api'
// Quill Editor
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import { quillEditor } from 'vue-quill-editor'
// Quill Editor End
import Store from '../../store'

export default {
	name: 'myQuillEditor',
	components: { quillEditor },
	setup() {
		// const vm = getCurrentInstance()
		let content = ref('')
		const editorOption = {
			modules: {
				toolbar: [
					['bold', 'italic', 'underline', 'blockquote', 'clean'],
					[{ header: 1 }, { header: 2 }, { header: [1, 2, 3, 4, 5, 6, false] }],
					[{ list: 'ordered' }, { list: 'bullet' }],
					[{ color: [] }, { background: [] }],
					['link', 'video'],
				],
			},
		}
		const onEditorBlur = () => {
			setStore()
		}
		// const onEditorBlur = quill => console.log('editor blur!', quill)
		const onEditorFocus = (quill) => console.log('editor focus!', quill)
		const onEditorReady = (quill) => console.log('editor ready!', quill)
		// const editor = computed(() => vm.$refs.myQuillEditor.quill)
		const setStore = () => {
			Store.dispatch('setStockCurrentDescription', content.value)
			let toggle = !Store.getters.getStockDesBtnToggle
			Store.dispatch('setStockDesBtnToggle', toggle)
		}
		return {
			content,
			editorOption,
			// editor,
			onEditorReady,
			onEditorFocus,
			onEditorBlur,
			quillEditor,
			setStore,
		}
	},
}
</script>
<style>
.margin-top-15 {
	margin-top: 15px;
}
</style>
