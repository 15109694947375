// user-i18n.js functions to switch or change the locale for i18n
const i18nService = require('../../plugins/i18n/i18n')

export default function userLang() {
	function changeLanguage(language) {
		localStorage.setItem('localei18n', language)
		i18nService.default.locale = language
	}
	function switchLanguage() {
		let locale = i18nService.default.locale
		switch (locale) {
			case 'en':
				i18nService.default.locale = 'se'
				changeLanguage('se')
				break
			case 'se':
				i18nService.default.locale = 'tr'
				changeLanguage('tr')
				break
			case 'tr':
				i18nService.default.locale = 'en'
				changeLanguage('en')
				break
		}
	}
	return { switchLanguage, changeLanguage }
}
