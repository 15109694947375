export function startLogos() {
	return {
		state: {
			logo: '',
			aboutUs: '',
			logoLink: '',
			watermark: '',
			logoWatermarkKey: 1,
		},
		getters: {
			getLogo: (state) => state.logo,
			getAboutUs: (state) => state.aboutUs,
			getLogoLink: (state) => state.logoLink,
			getWatermark: (state) => state.watermark,
			getLogoWatermarkKey: (state) => state.logoWatermarkKey,
		},
		mutations: {
			mutateLogo(state, payload) {
				state.logo = payload
			},
			mutateAboutUs(state, payload) {
				state.aboutUs = payload
			},
			mutateLogoLink(state, payload) {
				state.logoLink = payload
			},
			mutateWatermark(state, payload) {
				state.watermark = payload
			},
			mutateLogoWatermarkKey(state, payload) {
				state.logoWatermarkKey = payload
			},
		},
		actions: {
			setLogo({ commit }, payload) {
				commit('mutateLogo', payload)
			},
			setAboutUs({ commit }, payload) {
				commit('mutateAboutUs', payload)
			},
			setLogoLink({ commit }, payload) {
				commit('mutateLogoLink', payload)
			},
			setWatermark({ commit }, payload) {
				commit('mutateWatermark', payload)
			},
			setLogoWatermarkKey({ commit }, payload) {
				commit('mutateLogoWatermarkKey', payload)
			},
		},
	}
}
