<template>
	<div class="hello">
		<p>{{ label }}</p>
		<button @click="storeLocale">Change Language</button>
	</div>
</template>
<script>
export default {
	name: 'HelloWorld',
	components: {},
	data() {
		return {}
	},
	computed: {
		label() {
			return this.$t('message.pages.navbar.customer')
		},
	},
	beforeMount() {
		this.storeLocale()
	},
	methods: {
		storeLocale() {
			if (localStorage.getItem('localeI18n') == undefined) {
				localStorage.setItem('localeI18n', 'en')
			}
			this.$i18n.locale = localStorage.getItem('localeI18n')
		},
	},
}
</script>
<style scoped>
h1,
h2 {
	font-weight: normal;
}
ul {
	list-style-type: none;
	padding: 0;
}
li {
	display: inline-block;
	margin: 0 10px;
}
a {
	color: #42b983;
}
</style>
