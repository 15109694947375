const se = {
	message: {
		pages: {
			home: {
				msg: 'Hej, Välkommen',
			},
			navbar: {
				language: 'Svenska',
				login: 'Logga in',
				logout: 'Logga ut',
				home: 'Hemsidan',
				stock: 'Stock',
				customer: 'Kund',
				order: 'Order',
				dispatch: 'Följesedel',
				invoice: 'Faktura',
				accounting: 'Redovisning',
				dashboard: 'Kontrollpanel',
				settings: 'Inställningar',
			},
			aboutus: {
				aboutus: 'Om oss',
			},
			login: {
				login: 'Logga in',
				email: 'Epost',
				password: 'Lösenord',
				rememberme: 'Kom ihåg mig',
				ex: 't.ex',
				message: 'Du måste logga in',
			},
			footer: {
				switchTheLanguage: 'Ändra språket',
				switchTheCompany: 'Ändra företaget',
				user: 'Användare: ',
				company: 'Företag: ',
				switchCompanyMessage: 'Välj företag',
				unselected: 'Inget utvalt',
			},
			card: {
				moreinfo: 'Mer Info',
				edit: 'Anpassa',
			},
			vidle: {
				line1: 'Du är ledig i 4 minuter och 30 sekunder.',
				line2: 'Efter 30 sekunder kommer du att loggas ut automatiskt!',
				line3: 'Om du vill hålla dig inloggad klickar du på OK!',
				msg: 'Du har loggats ut',
			},
			errors: {
				e404: {
					message: 'Tyvärr ingenting här ...',
					back: 'Gå tillbaka',
				},
			},
		},
	},
}

export default se
