export function selectedDocuments() {
	return {
		state: {
			SelectedOrder: '',
			SelectedDispatch: '',
			SelectedInvoice: '',
		},
		getters: {
			getSelectedOrder: (state) => state.SelectedOrder,
			getSelectedDispatch: (state) => state.SelectedDispatch,
			getSelectedInvoice: (state) => state.SelectedInvoice,
		},
		mutations: {
			mutateSelectedOrder(state, payload) {
				state.SelectedOrder = payload
			},
			mutateSelectedDispatch(state, payload) {
				state.SelectedDispatch = payload
			},
			mutateSelectedInvoice(state, payload) {
				state.SelectedInvoice = payload
			},
		},
		actions: {
			setSelectedOrder({ commit }, payload) {
				commit('mutateSelectedOrder', payload)
			},
			setSelectedDispatch({ commit }, payload) {
				commit('mutateSelectedDispatch', payload)
			},
			setSelectedInvoice({ commit }, payload) {
				commit('mutateSelectedInvoice', payload)
			},
		},
	}
}
