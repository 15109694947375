import { reactive, toRefs } from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
Vue.use(VueCompositionApi)

const getData = (docDef) => {
	return new Promise((resolve, reject) => {
		const dd = docDef
		if (dd !== undefined || null) {
			resolve(dd)
		} else {
			const errorObject = {
				msg: "Data couldn't be fetched from store",
			}
			reject(errorObject)
		}
	})
}
const createReport = (dd) => {
	return new Promise((resolve, reject) => {
		if (dd !== undefined || null) {
			// console.log('******//// dd ****', dd)
			const pdfMake = require('pdfmake/build/pdfmake.js')
			const pdfFonts = require('pdfmake/build/vfs_fonts.js')
			pdfMake.vfs = pdfFonts.pdfMake.vfs
			const pdf = pdfMake.createPdf(dd)
			resolve(pdf)
		} else {
			const errorObject = {
				msg: "The report couldn't be created",
			}
			reject(errorObject)
		}
	})
}
const openPDF = (dd) => {
	getData(dd)
		.then((data) => createReport(data))
		.then((pdf) => pdf.open())
}
const downloadPDF = (dd, rh) => {
	const fileName = String(rh.reportType + '-' + rh.reportNumber)
		.trim()
		.replaceAll(' ', '-')
		.toLowerCase()
	getData(dd)
		.then((data) => createReport(data))
		.then((pdf) => pdf.download(`${fileName}.pdf`))
}
const printPDF = (dd) => {
	getData(dd)
		.then((data) => createReport(data))
		.then((pdf) => pdf.print())
}
const emailPDF = () =>
	alert(
		'This function is not supported on the current version of application!!!',
	)
const state = reactive({
	number: 1,
})
export default function useProcessReportMultiDocument() {
	return {
		...toRefs(state),
		getData,
		createReport,
		openPDF,
		downloadPDF,
		printPDF,
		emailPDF,
	}
}
