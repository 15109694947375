/** @format */

import {
	reactive,
	toRefs,
	// ref,
	// computed
} from '@vue/composition-api'
import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
// import Store from '@/store'
// import axios from 'axios'

Vue.use(VueCompositionApi)
// ! ----------------------------------------------------------------------
const getData = (docDef) => {
	return new Promise((resolve, reject) => {
		const dd = docDef
		if (dd !== undefined || null) {
			resolve(dd)
		} else {
			const errorObject = {
				msg: "Data couldn't be fetched from store",
			}
			reject(errorObject)
		}
	})
}
// ! ------------------------------------------------------------------
const createReport = (dd) => {
	return new Promise((resolve, reject) => {
		if (dd !== undefined || null) {
			// console.log('******//// dd ****', dd)
			const pdfMake = require('pdfmake/build/pdfmake.js')
			const pdfFonts = require('pdfmake/build/vfs_fonts.js')
			pdfMake.vfs = pdfFonts.pdfMake.vfs
			const pdf = pdfMake.createPdf(dd)
			resolve(pdf)
		} else {
			const errorObject = {
				msg: "The report couldn't be created",
			}
			reject(errorObject)
		}
	})
}
// ! ------------------------------------------------------------------
const openPDF = (dd) => {
	getData(dd)
		.then((data) => createReport(data.docDefinition))
		.then((pdf) => pdf.open())
}
// ! ------------------------------------------------------------------
const downloadPDF = (dd, rh) => {
	const fileName = String(rh.reportType + '-' + rh.reportNumber)
		.trim()
		.replaceAll(' ', '-')
		.toLowerCase()
	getData(dd)
		.then((data) => createReport(data.docDefinition))
		.then((pdf) => pdf.download(`${fileName}.pdf`))
}
// ! ------------------------------------------------------------------
const printPDF = (dd) => {
	getData(dd)
		.then((data) => createReport(data.docDefinition))
		.then((pdf) => pdf.print())
}
// todo create stream and send to backend
// const docType = ref('Fakturanummer')
// const relatedDocs = ref('Relaterade följesedlar')
// let formData = new FormData()
// ! ------------------------------------------------------------------------
// const createStream = (dd) => {
// 	return new Promise((resolve, reject) => {
// 		if (dd !== undefined || null) {
// 			const pdfMake = require('pdfmake/build/pdfmake.js')
// 			const pdfFonts = require('pdfmake/build/vfs_fonts.js')
// 			pdfMake.vfs = pdfFonts.pdfMake.vfs
// 			setTimeout(() => {
// 				const pdfDocGenerator = pdfMake.createPdf(dd)
// 				pdfDocGenerator.getBlob((blob) => {
// 					formData.set('fileName', fileName)
// 					formData.set('pdfFileStream', blob, 'blob.pdf')
// 					console.log('**** after append blob', ...formData.entries())
// 					// isEmailDisabled.value = false
// 					// isEmailTagDisabled.value = false
// 				})
// 			}, 1000)
// 			resolve(formData)
// 		} else {
// 			const errorObject = {
// 				msg: "The stream couldn't be created",
// 			}
// 			reject(errorObject)
// 		}
// 	})
// }
// ! ------------------------------------------------------------------------
// const init = () => {
// 	for (const key of formData.keys()) {
// 		formData.delete(key)
// 	}
// 	// isEmailDisabled.value = true
// 	// isEmailTagDisabled.value = false
// 	// hasNoEmail.value = false
// 	// let dd = getInvoiceReportData()
// 	console.log('dd', dd)
// 	const relatedDispatchesNumbersArray =
// 		Store.getters.getInvoiceReportData.related_dispatches.map(
// 			(item) => item.dispatch_number,
// 		)
// 	const relatedDispatches = String(relatedDispatchesNumbersArray).replaceAll(
// 		',',
// 		', ',
// 	)
// 	const toPayAmount = dd.content[9].table.body[0][2].text
// 	console.log('toPayAmount', toPayAmount)
// 	const reportData = computed(() => Store.getters.getInvoiceReportData)
// 	console.log('>>>> reportData.value', reportData.value)
// 	formData.delete('pdfFileStream')
// 	formData.set('ourMail', `Kontakt: ${reportData.value.company.company_email}`)
// 	formData.set('ourCompany', reportData.value.company.company_name)
// 	formData.set('customerMail', reportData.value.customer.customer_email)
// 	formData.set('reference', 'Finans Avdelning')
// 	formData.set('subject', 'Ni har en faktura => ')
// 	formData.set('tel', reportData.value.company.company_tel)
// 	formData.set(
// 		'docNumber',
// 		`${docType.value}: ${reportData.value.invoice_number}`,
// 	)
// 	formData.set('relatedDocuments', `${relatedDocs.value}: ${relatedDispatches}`)
// 	formData.set('dueDate', reportData.value.invoice_due_date)
// 	formData.set('toPay', toPayAmount)
// 	formData.set('bg', reportData.value.company.company_bank_giro)
// 	formData.set('message', 'Ni kan kontakta med oss via epost')
// 	createStream(dd).then((formData) => {
// 		console.log('****from onMounted form data', ...formData.entries())
// 		console.log('createStream is fired')
// 	})
// 	// console.log('****from onMounted form data', ...formData.entries())
// }
// init()
const emailPDF = () =>
	alert(
		'This function is not supported on the current version of application!!!',
	)
const state = reactive({
	number: 1,
})
export default function useProcessReport() {
	return {
		...toRefs(state),
		getData,
		createReport,
		openPDF,
		downloadPDF,
		printPDF,
		emailPDF,
	}
}
