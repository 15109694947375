export function startOurCompanies() {
	return {
		// namespaced: true,//
		state: {
			ourCompanies: '',
		},
		getters: {
			getOurCompanies: (state) => state.ourCompanies,
		},
		mutations: {
			mutateOurCompanies(state, payload) {
				state.ourCompanies = payload
			},
		},
		actions: {
			setOurCompanies({ commit }, payload) {
				commit('mutateOurCompanies', payload)
			},
		},
	}
}
