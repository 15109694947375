<!-- @format -->

<template>
	<b-navbar>
		<template slot="brand">
			<b-navbar-item tag="router-link" :to="{ name: 'Home' }">
				<img
					style="display: block; width: 100px; height: auto"
					id="base64image"
					v-bind:src="currentLogo"
					alt="logo"
				/>
			</b-navbar-item>
			<b-dropdown aria-role="list">
				<button
					class="button btnMenu is-primary"
					slot="trigger"
					slot-scope="{ active }"
				>
					<span>Menu</span>
					<b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
				</button>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'Home' }"
					style="width: 300px"
				>
					Home
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'ViewSaleInvoices' }"
					v-if="$store.getters.getAccountantGuard"
				>
					Accountant Privileges
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'ChangeReminderDueDate' }"
					v-if="$store.getters.getDevGuard"
				>
					Dev Privileges
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'TimeIntervalRevenue' }"
					v-if="$store.getters.getAdminGuard"
				>
					Admin &nbsp; (Esc + a)
				</b-navbar-item>

				<b-navbar-item
					tag="router-link"
					:to="{ name: 'CustomerList' }"
					v-if="$store.getters.getCompaniesGuard"
				>
					Customer &nbsp; (Esc + c)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'AddEditStockItem' }"
					v-if="$store.getters.getStockGuard"
				>
					Stock &nbsp; (Esc + s)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'SearchDocument' }"
					v-if="$store.getters.getUtilitiesGuard"
				>
					Utilities &nbsp; (Esc + u)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'AddOrder' }"
					v-if="$store.getters.getOrderGuard"
				>
					Order &nbsp; (Esc + o)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'ViewOrdersToDispatch' }"
					v-if="$store.getters.getDispatchGuard"
				>
					Dispatch &nbsp; (Esc + d)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'CreateNewRouteList' }"
					v-if="$store.getters.getAdminGuard"
				>
					Delivery
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'ViewDispatchesToInvoice' }"
					v-if="$store.getters.getSaleInvoiceGuard"
				>
					Sale Invoice &nbsp; (Esc + i)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'AddPurchaseInvoice' }"
					v-if="$store.getters.getPurchaseInvoiceGuard"
				>
					Purchase Invoice &nbsp; (Esc + p)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'SendReminder' }"
					v-if="$store.getters.getReminderGuard"
				>
					Reminder &nbsp; (Esc + r)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'AddTransaction' }"
					v-if="$store.getters.getTransactionsGuard"
				>
					Transactions &nbsp; (Esc + t)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'Accounting' }"
					v-if="$store.getters.getAccountingGuard"
				>
					Accounting &nbsp; (Esc + b)
				</b-navbar-item>
				<b-navbar-item
					tag="router-link"
					:to="{ name: 'Marketing' }"
					v-if="$store.getters.getAdminGuard"
				>
					Marketing &nbsp; (Esc + m)
				</b-navbar-item>
			</b-dropdown>

			<b-navbar-item tag="div">
				<div class="buttons">
					<a class="button is-primary" v-on:click="handleUserLogout">
						{{ $t('message.pages.navbar.logout') }}
					</a>
				</div>
			</b-navbar-item>
		</template>
		<template slot="burger">
			<b-navbar-item> </b-navbar-item>
		</template>
	</b-navbar>
</template>

<script>
import userLang from '../../composables/i18n/user-i18n'
import auth from '../../plugins/auth/authService'
import { computed, onMounted, watchEffect } from '@vue/composition-api'
import Store from '@/store'
// import dateformat from 'dateformat'
import moment from 'moment'

export default {
	name: 'NavBar',
	setup() {
		const currentLogo = computed(() => Store.getters.getLogoLink)
		function handleUserLogout() {
			auth.logOut()
		}
		watchEffect(() => console.log(Store.getters.getDueDate))
		const getDueDate = () => {
			let date = new Date()
			let daysToSubtract
			switch (moment(date).day()) {
				// Sunday = 4 days
				case 0:
					daysToSubtract = 4
					break
				// Monday and Tuesday = 5 days
				case 1:
				case 2:
					daysToSubtract = 5
					break
				// Subtract 2 days otherwise.
				default:
					daysToSubtract = 3
					break
			}
			return moment(date).subtract(daysToSubtract, 'days').format('YYYY-MM-DD')
		}
		// const a = getDueDate()
		Store.dispatch('setDueDate', getDueDate())
		onMounted(() => {
			// const a = getDueDate()
			Store.dispatch('setDueDate', getDueDate())
		})
		// get today
		Store.dispatch('setToday', new Date())
		// --------------------------------------------------------------
		return {
			...userLang(),
			handleUserLogout,
			currentLogo,
		}
	},
}
</script>
<style scoped>
.btnMenu {
	margin-top: 8px;
	width: 150px;
}
</style>
