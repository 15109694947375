export function orderEdit() {
	return {
		state: {
			OrderOurCompany: null,
			CurrentOrderNumber: null,
			CurrentOrderDate: null,
			CurrentOrderCustomerID: null,
			CurrentOrderCustomerNickName: null,
			CurrentOrderCustomerTitle: null,
			CurrentOrderCustomerOrgNum: null,
			KeyEditOrderForm: 1,
			KeyEditOrderGetOrderLines: 1,
			ShowFormEditOrderSearch: true,
			ShowFormEditOrderHeaders: false,
			ShowFormEditOrderAddOrderLines: false,
			ShowFormEditOrderSelectStockItem: false,
			ShowFormEditOrderGetOrderLines: false,
			ShowFormEditOrderClose: false,
			CancelLockOrderNumber: null,
		},
		getters: {
			getOrderOurCompany: (state) => state.OrderOurCompany,
			getCurrentOrderNumber: (state) => state.CurrentOrderNumber,
			getCurrentOrderDate: (state) => state.CurrentOrderDate,
			getCurrentOrderCustomerID: (state) => state.CurrentOrderCustomerID,
			getCurrentOrderCustomerNickName: (state) =>
				state.CurrentOrderCustomerNickName,
			getCurrentOrderCustomerTitle: (state) => state.CurrentOrderCustomerTitle,
			getCurrentOrderCustomerOrgNum: (state) =>
				state.CurrentOrderCustomerOrgNum,
			getKeyEditOrderForm: (state) => state.KeyEditOrderForm,
			getKeyEditOrderGetOrderLines: (state) => state.KeyEditOrderGetOrderLines,
			getShowFormEditOrderSearch: (state) => state.ShowFormEditOrderSearch,
			getShowFormEditOrderHeaders: (state) => state.ShowFormEditOrderHeaders,
			getShowFormEditOrderAddOrderLines: (state) =>
				state.ShowFormEditOrderAddOrderLines,
			getShowFormEditOrderSelectStockItem: (state) =>
				state.ShowFormEditOrderSelectStockItem,
			getShowFormEditOrderGetOrderLines: (state) =>
				state.ShowFormEditOrderSelectStockItem,
			getShowFormEditOrderClose: (state) => state.ShowFormEditOrderClose,
			getCancelLockOrderNumber: (state) => state.CancelLockOrderNumber,
		},
		mutations: {
			mutateOrderOurCompany(state, payload) {
				state.OrderOurCompany = payload
			},
			mutateCurrentOrderNumber(state, payload) {
				state.CurrentOrderNumber = payload
			},
			mutateCurrentOrderDate(state, payload) {
				state.CurrentOrderDate = payload
			},
			mutateCurrentOrderCustomerID(state, payload) {
				state.CurrentOrderCustomerID = payload
			},
			mutateCurrentOrderCustomerNickName(state, payload) {
				state.CurrentOrderCustomerNickName = payload
			},
			mutateCurrentOrderCustomerTitle(state, payload) {
				state.CurrentOrderCustomerTitle = payload
			},
			mutateCurrentOrderCustomerOrgNum(state, payload) {
				state.CurrentOrderCustomerOrgNum = payload
			},
			mutateKeyEditOrderForm(state, payload) {
				state.KeyEditOrderForm = payload
			},
			mutateKeyEditOrderGetOrderLines(state, payload) {
				state.KeyEditOrderGetOrderLines = payload
			},
			mutateShowFormEditOrderSearch(state, payload) {
				state.ShowFormEditOrderSearch = payload
			},
			mutateShowFormEditOrderHeaders(state, payload) {
				state.ShowFormEditOrderHeaders = payload
			},
			mutateShowFormEditOrderAddOrderLines(state, payload) {
				state.ShowFormEditOrderAddOrderLines = payload
			},
			mutateShowFormEditOrderSelectStockItem(state, payload) {
				state.ShowFormEditOrderSelectStockItem = payload
			},
			mutateShowFormEditOrderGetOrderLines(state, payload) {
				state.ShowFormEditOrderGetOrderLines = payload
			},
			mutateShowFormEditOrderClose(state, payload) {
				state.ShowFormEditOrderClose = payload
			},
			mutateCancelLockOrderNumber(state, payload) {
				state.CancelLockOrderNumber = payload
			},
		},
		actions: {
			setOrderOurCompany({ commit }, payload) {
				commit('mutateOrderOurCompany', payload)
			},
			setCurrentOrderNumber({ commit }, payload) {
				commit('mutateCurrentOrderNumber', payload)
			},
			setCurrentOrderDate({ commit }, payload) {
				commit('mutateCurrentOrderDate', payload)
			},
			setCurrentOrderCustomerID({ commit }, payload) {
				commit('mutateCurrentOrderCustomerID', payload)
			},
			setCurrentOrderCustomerNickName({ commit }, payload) {
				commit('mutateCurrentOrderCustomerNickName', payload)
			},
			setCurrentOrderCustomerTitle({ commit }, payload) {
				commit('mutateCurrentOrderCustomerTitle', payload)
			},
			setCurrentOrderCustomerOrgNum({ commit }, payload) {
				commit('mutateCurrentOrderCustomerOrgNum', payload)
			},
			setKeyEditOrderForm({ commit }, payload) {
				commit('mutateKeyEditOrderForm', payload)
			},
			setKeyEditOrderGetOrderLines({ commit }, payload) {
				commit('mutateKeyEditOrderGetOrderLines', payload)
			},
			setShowFormEditOrderSearch({ commit }, payload) {
				commit('mutateShowFormEditOrderSearch', payload)
			},
			setShowFormEditOrderHeaders({ commit }, payload) {
				commit('mutateShowFormEditOrderHeaders', payload)
			},
			setShowFormEditOrderAddOrderLines({ commit }, payload) {
				commit('mutateShowFormEditOrderAddOrderLines', payload)
			},
			setShowFormEditOrderSelectStockItem({ commit }, payload) {
				commit('mutateShowFormEditOrderSelectStockItem', payload)
			},
			setShowFormEditOrderGetOrderLines({ commit }, payload) {
				commit('mutateShowFormEditOrderGetOrderLines', payload)
			},
			setShowFormEditOrderClose({ commit }, payload) {
				commit('mutateShowFormEditOrderClose', payload)
			},
			setCancelLockOrderNumber({ commit }, payload) {
				commit('mutateCancelLockOrderNumber', payload)
			},
		},
	}
}
