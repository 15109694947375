export function selectStockItem() {
	return {
		state: {
			EditOrderGoodsTransactionUUID: '',
			EditOrderLineInfo: '',
			EditOrderSelectedStockItem: '',
		},
		getters: {
			getEditOrderGoodsTransactionUUID: (state) =>
				state.EditOrderGoodsTransactionUUID,
			getEditOrderLineInfo: (state) => state.EditOrderLineInfo,
			getEditOrderSelectedStockItem: (state) =>
				state.EditOrderSelectedStockItem,
		},
		mutations: {
			mutateEditOrderGoodsTransactionUUID(state, payload) {
				state.EditOrderGoodsTransactionUUID = payload
			},
			mutateEditOrderLineInfo(state, payload) {
				state.EditOrderLineInfo = payload
			},
			mutateEditOrderSelectedStockItem(state, payload) {
				state.EditOrderSelectedStockItem = payload
			},
		},
		actions: {
			setEditOrderGoodsTransactionUUID({ commit }, payload) {
				commit('mutateEditOrderGoodsTransactionUUID', payload)
			},
			setEditOrderLineInfo({ commit }, payload) {
				commit('mutateEditOrderLineInfo', payload)
			},
			setEditOrderSelectedStockItem({ commit }, payload) {
				commit('mutateEditOrderSelectedStockItem', payload)
			},
		},
	}
}
