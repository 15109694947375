export function dispatchLayout() {
	return {
		state: {
			DispatchHeaderKey: 1,
			DispatchCustomersKey: 1,
			DispatchSelectedCustomerKey: 1,
			DispatchLinesKey: 1,
			DispatchLineEditKey: 1,
			DispatchFooterButtonsKey: 1,
		},
		getters: {
			getDispatchHeaderKey: (state) => state.DispatchHeaderKey,
			getDispatchCustomersKey: (state) => state.DispatchCustomersKey,
			getDispatchSelectedCustomerKey: (state) =>
				state.DispatchSelectedCustomerKey,
			getDispatchLinesKey: (state) => state.DispatchLinesKey,
			getDispatchLineEditKey: (state) => state.DispatchLineEditKey,
			getDispatchFooterButtonsKey: (state) => state.DispatchFooterButtonsKey,
		},
		mutations: {
			mutateDispatchHeaderKey(state, payload) {
				state.DispatchHeaderKey = payload
			},
			mutateDispatchCustomersKey(state, payload) {
				state.DispatchCustomersKey = payload
			},
			mutateDispatchSelectedCustomerKey(state, payload) {
				state.DispatchSelectedCustomerKey = payload
			},
			mutateDispatchLinesKey(state, payload) {
				state.DispatchLinesKey = payload
			},
			mutateDispatchLineEditKey(state, payload) {
				state.DispatchLineEditKey = payload
			},
			mutateDispatchFooterButtonsKey(state, payload) {
				state.DispatchFooterButtonsKey = payload
			},
		},
		actions: {
			setDispatchHeaderKey({ commit }, payload) {
				commit('mutateDispatchHeaderKey', payload)
			},
			setDispatchCustomersKey({ commit }, payload) {
				commit('mutateDispatchCustomersKey', payload)
			},
			setDispatchSelectedCustomerKey({ commit }, payload) {
				commit('mutateDispatchSelectedCustomerKey', payload)
			},
			setDispatchLinesKey({ commit }, payload) {
				commit('mutateDispatchLinesKey', payload)
			},
			setDispatchLineEditKey({ commit }, payload) {
				commit('mutateDispatchLineEditKey', payload)
			},
			setDispatchFooterButtonsKey({ commit }, payload) {
				commit('mutateDispatchFooterButtonsKey', payload)
			},
		},
	}
}
