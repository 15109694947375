export function storeDocumentLines() {
	return {
		state: {
			// Document Lines
			SelectedStockItemForOrder: {},
			CurrentOrderType: '',
			CurrentDispatchType: '',
			CurrentInvoiceType: '',
			ShowSubGetOrderLines: true,
			ShowSubGetSelectStockItem: true,
			ShowSubAddOrderLinesForm: false,
			ShowSaveOrderButton: false,
			ShowSaveDispatchButton: false,
			ShowSaveInvoiceButton: false,
			PricesForOrderLine: {
				campaignPrice: 0,
				customerSpecialPrice: 0,
				customerDiscountedPrice: 0,
				customerInvoicePrice: 0,
				customerInvoiceExchangePrice: 0,
			},
			// Store sale-invoice prices for goods_transaction table
			GoodsTransactionsInvoicePrice: 0,
			GoodsTransactionsInvoicePriceExchange: 0,
		},
		getters: {
			getSelectedStockItemForOrder: (state) => state.SelectedStockItemForOrder,
			getCurrentOrderType: (state) => state.CurrentOrderType,
			getCurrentDispatchType: (state) => state.CurrentDispatchType,
			getCurrentInvoiceType: (state) => state.CurrentInvoiceType,
			getShowSubGetOrderLines: (state) => state.ShowSubGetOrderLines,
			getShowSubGetSelectStockItem: (state) => state.ShowSubGetSelectStockItem,
			getShowSubAddOrderLinesForm: (state) => state.ShowSubAddOrderLinesForm,
			getShowSaveOrderButton: (state) => state.ShowSaveOrderButton,
			getShowSaveDispatchButton: (state) => state.ShowSaveDispatchButton,
			getShowSaveInvoiceButton: (state) => state.ShowSaveInvoiceButton,
			getPricesForOrderLine: (state) => state.PricesForOrderLine,
			getGoodsTransactionsInvoicePrice: (state) =>
				state.GoodsTransactionsInvoicePrice,
			getGoodsTransactionsInvoicePriceExchange: (state) =>
				state.GoodsTransactionsInvoicePriceExchange,
		},
		mutations: {
			mutateSelectedStockItemForOrder(state, payload) {
				state.SelectedStockItemForOrder = payload
			},
			mutateCurrentOrderType(state, payload) {
				state.CurrentOrderType = payload
			},
			mutateCurrentDispatchType(state, payload) {
				state.CurrentDispatchType = payload
			},
			mutateCurrentInvoiceType(state, payload) {
				state.CurrentInvoiceType = payload
			},
			mutateShowSubGetOrderLines(state, payload) {
				state.ShowSubGetOrderLines = payload
			},
			mutateShowSubGetSelectStockItem(state, payload) {
				state.ShowSubGetSelectStockItem = payload
			},
			mutateShowSubAddOrderLinesForm(state, payload) {
				state.ShowSubAddOrderLinesForm = payload
			},
			mutateShowSaveOrderButton(state, payload) {
				state.ShowSaveOrderButton = payload
			},
			mutateShowSaveDispatchButton(state, payload) {
				state.ShowSaveDispatchButton = payload
			},
			mutateShowSaveInvoiceButton(state, payload) {
				state.ShowSaveInvoiceButton = payload
			},
			mutatePricesForOrderLine(state, payload) {
				state.PricesForOrderLine = payload
			},
			mutatePricesForOrderLineInvoicePrice(state, payload) {
				state.PricesForOrderLine.customerInvoicePrice = payload
			},
			mutatePricesForOrderLineInvoiceExchangePrice(state, payload) {
				state.PricesForOrderLine.customerInvoiceExchangePrice = payload
			},
			mutateGoodsTransactionsInvoicePrice(state, payload) {
				state.GoodsTransactionsInvoicePrice = payload
			},
			mutateGoodsTransactionsInvoicePriceExchange(state, payload) {
				state.GoodsTransactionsInvoicePriceExchange = payload
			},
		},
		actions: {
			setSelectedStockItemForOrder({ commit }, payload) {
				commit('mutateSelectedStockItemForOrder', payload)
			},
			setCurrentOrderType({ commit }, payload) {
				commit('mutateCurrentOrderType', payload)
			},
			setCurrentDispatchType({ commit }, payload) {
				commit('mutateCurrentDispatchType', payload)
			},
			setCurrentInvoiceType({ commit }, payload) {
				commit('mutateCurrentInvoiceType', payload)
			},
			setShowSubGetOrderLines({ commit }, payload) {
				commit('mutateShowSubGetOrderLines', payload)
			},
			setShowSubGetSelectStockItem({ commit }, payload) {
				commit('mutateShowSubGetSelectStockItem', payload)
			},
			setShowSubAddOrderLinesForm({ commit }, payload) {
				commit('mutateShowSubAddOrderLinesForm', payload)
			},
			setShowSaveOrderButton({ commit }, payload) {
				commit('mutateShowSaveOrderButton', payload)
			},
			setShowSaveDispatchButton({ commit }, payload) {
				commit('mutateShowSaveDispatchButton', payload)
			},
			setShowSaveInvoiceButton({ commit }, payload) {
				commit('mutateShowSaveInvoiceButton', payload)
			},
			setPricesForOrderLine({ commit }, payload) {
				commit('mutatePricesForOrderLine', payload)
			},
			setPricesForOrderLineInvoicePrice({ commit }, payload) {
				commit('mutatePricesForOrderLineInvoicePrice', payload)
			},
			setPricesForOrderLineInvoiceExchangePrice({ commit }, payload) {
				commit('mutatePricesForOrderLineInvoiceExchangePrice', payload)
			},
			setH({ commit }, payload) {
				commit('mutateH', payload)
			},
			setGoodsTransactionsInvoicePrice({ commit }, payload) {
				commit('mutateGoodsTransactionsInvoicePrice', payload)
			},
			setGoodsTransactionsInvoicePriceExchange({ commit }, payload) {
				commit('mutateGoodsTransactionsInvoicePriceExchange', payload)
			},
		},
	}
}
