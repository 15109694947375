<template>
	<div>
		<div>
			<b-button
				class="is-info margin-top-15"
				@click="openPDF($props.reportData)"
				style="width: 200px"
				><i class="fas fa-search"></i>&nbsp; Preview
			</b-button>
		</div>
		<div>
			<b-button
				class="is-success margin-top-15"
				@click="downloadPDF($props.reportData, $props.reportHeaders)"
				style="width: 200px"
				><i class="fas fa-download"></i>&nbsp; Download</b-button
			>
		</div>
		<div>
			<b-button
				class="is-danger margin-top-15"
				@click="printPDF($props.reportData)"
				style="width: 200px"
				><i class="fas fa-print" style="text-align: left"></i>&nbsp;
				Print</b-button
			>
		</div>
		<div>
			<b-button
				class="is-primary margin-top-15"
				@click="emailPDF"
				style="width: 200px"
				><i class="fas fa-envelope-square"></i>&nbsp; Email</b-button
			>
		</div>
		<div>
			<b-button
				class="is-danger is-inverted margin-top-15"
				@click="$store.dispatch('setShowProcessReport', false)"
				style="width: 200px"
				><i class="fas fa-times"></i>&nbsp; Close</b-button
			>
		</div>
	</div>
</template>
<script>
import useProcessReport from '@/_srcv2/composables/process-report/useProcessReport.js'
export default {
	name: 'ProcessReport',
	props: {
		reportData: Object,
		reportHeaders: Object,
	},
	setup() {
		const { openPDF, downloadPDF, printPDF, emailPDF } = useProcessReport()
		return {
			openPDF,
			downloadPDF,
			printPDF,
			emailPDF,
		}
	},
}
</script>

<style scoped></style>
