export function customerPrices() {
	return {
		state: {
			OStockNormalPrice: 0,
			OStockCampaignPrice: 0,
			OCustomerSpecialPrice: 0,
			OCustomerDiscountedPrice: 0,
			OCustomerInvoicePrice: 0,
			OCustomerInvoiceVatPercent: 0,
			OCustomerInvoiceVatCredit: 0,
		},
		getters: {
			getOStockNormalPrice: (state) => state.OStockNormalPrice,
			getOStockCampaignPrice: (state) => state.OStockCampaignPrice,
			getOCustomerSpecialPrice: (state) => state.OCustomerSpecialPrice,
			getOCustomerDiscountedPrice: (state) => state.OCustomerDiscountedPrice,
			getOCustomerInvoicePrice: (state) => state.OCustomerInvoicePrice,
			getOCustomerInvoiceVatPercent: (state) =>
				state.OCustomerInvoiceVatPercent,
			getOCustomerInvoiceVatCredit: (state) => state.OCustomerInvoiceVatCredit,
		},
		mutations: {
			mutateOStockNormalPrice(state, payload) {
				state.OStockNormalPrice = payload
			},
			mutateOStockCampaignPrice(state, payload) {
				state.OStockCampaignPrice = payload
			},
			mutateOCustomerSpecialPrice(state, payload) {
				state.OCustomerSpecialPrice = payload
			},
			mutateOCustomerDiscountedPrice(state, payload) {
				state.OCustomerDiscountedPrice = payload
			},
			mutateOCustomerInvoicePrice(state, payload) {
				state.OCustomerInvoicePrice = payload
			},
			mutateOCustomerInvoiceVatPercent(state, payload) {
				state.OCustomerInvoiceVatPercent = payload
			},
			mutateOCustomerInvoiceVatCredit(state, payload) {
				state.OCustomerInvoiceVatCredit = payload
			},
		},
		actions: {
			setOStockNormalPrice({ commit }, payload) {
				commit('mutateOStockNormalPrice', payload)
			},
			setOStockCampaignPrice({ commit }, payload) {
				commit('mutateOStockCampaignPrice', payload)
			},
			setOCustomerSpecialPrice({ commit }, payload) {
				commit('mutateOCustomerSpecialPrice', payload)
			},
			setOCustomerDiscountedPrice({ commit }, payload) {
				commit('mutateOCustomerDiscountedPrice', payload)
			},
			setOCustomerInvoicePrice({ commit }, payload) {
				commit('mutateOCustomerInvoicePrice', payload)
			},
			setOCustomerInvoiceVatPercent({ commit }, payload) {
				commit('mutateOCustomerInvoiceVatPercent', payload)
			},
			setOCustomerInvoiceVatCredit({ commit }, payload) {
				commit('mutateOCustomerInvoiceVatCredit', payload)
			},
		},
	}
}
