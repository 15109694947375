import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from './languages'

const locales = ['en', 'se', 'tr']

function checkLocales(locale) {
	return locale === localStorage.getItem('localei18n')
}

if (
	!locales.some(checkLocales) ||
	localStorage.getItem('localei18n') === null
) {
	localStorage.setItem('localei18n', 'en')
}

Vue.use(VueI18n)

const i18n = new VueI18n({
	locale: localStorage.getItem('localei18n'),
	messages,
})

export default i18n
