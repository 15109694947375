/* eslint-disable */
import auth0 from "auth0-js"
import { EventEmitter } from "events"
import authConfig from "../../../auth_config.json"
import Store from '../../store/index'

import {
  lsSetLogInfo,
  lsSetTokenInfo,
  lsSetProfileInfo,
  lsGetLogInfo,
  lsGetTokenInfo,
  lsRemoveAll,
  logInfo } from '../lsstore/index'

const webAuth = new auth0.WebAuth({
  domain: authConfig.domain,
  redirectUri: `${window.location.origin}/callback`,
  clientID: authConfig.clientId,
  responseType: "token id_token",
  scope: "openid profile"
})

const loginEvent = "loginEvent"

class AuthService extends EventEmitter {
  idToken = null
  profile = null
  tokenExpiry = null
  sub = null
  userGroups = []
  userRoles = []
  userName = null

  login(customState) {
    webAuth.authorize()
  }

  logOut() {
    lsRemoveAll() // delete later ***********************************************

    this.idToken = null
    this.profile = null
    this.tokenExpiry = null
    this.sub = null

    webAuth.logout({
      returnTo: `${window.location.origin}`
    })

    this.emit(loginEvent, { loggedIn: false })
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      webAuth.parseHash((err, authResult) => {
        if (err) {
          reject(err)
        } else {
          this.localLogin(authResult)
          resolve(authResult.idToken)
        }
      })
    })
  }

  isAuthenticated() {
    return (
      lsGetLogInfo('li') === 'true' // delete later ****************************
      // Store.getters.getIsLoggedIn
    )
  }

  // getSub() {
  //   return this.sub
  // }

  // isIdTokenValid() {
  //   return (
  //     this.idToken &&
  //     this.tokenExpiry &&
  //     Date.now() < this.tokenExpiry
  //   )
  // }

  // getIdToken() {
  //   return new Promise((resolve, reject) => {
  //     if (this.isIdTokenValid()) {
  //       resolve(this.idToken)
  //     } else if (this.isAuthenticated()) {
  //       this.renewTokens().then(authResult => {
  //         resolve(authResult.idToken)
  //       }, reject)
  //     } else {
  //       resolve()
  //     }
  //   })
  // }

  localLogin(authResult) {
    this.idToken = authResult.idToken
    this.profile = authResult.idTokenPayload
    this.sub = authResult.sub

    // Convert the expiry time from seconds to milliseconds,
    // required by the Date constructor
    this.tokenExpiry = new Date(this.profile.exp * 1000)

    lsSetLogInfo('true') // delete later ************************************************
    lsSetTokenInfo(authResult.idToken) // delete later ************************************************
    this.emit(loginEvent, {
      loggedIn: true,
      profile: authResult.idTokenPayload,
      state: authResult.appState || {},
      sub: authResult.sub
    })

    this.getUserInfo()

  }
  getUserInfo() {
    let key = this.profile
    // console.log('key in authService.js', key)
    let authString = JSON.stringify(key)
      .replace('https://hasura.io/jwt/claims', 'hasura')
      .replace('https://hasura.io/jwt/claims/authorization', 'auth')
    let authObj = JSON.parse(authString)
    this.userGroups = authObj.auth.groups
    this.userRoles = authObj.auth.roles
    this.userName = authObj.nickname
    Store.dispatch('setUserName', this.userName).then(() => {
      Store.dispatch('setUserGroups', this.userGroups)
    })
      .then(() => {
      Store.dispatch('setUserRoles', this.userRoles)
    })
    .catch(() => {
      console.log('Error with Login')
    })
  }

  renewTokens() {
    return new Promise((resolve, reject) => {
      if (lsGetLogInfo('li') !== 'true') {
        return reject("Not logged in")
      }

      webAuth.checkSession({}, (err, authResult) => {
        if (err) {
          console.log('Session is checked, err raised, user dropped down')
          lsRemoveAll()
          reject(err)
        } else {
          this.localLogin(authResult)
          resolve(authResult)
        }
      })
    })
  }
}

const service = new AuthService()

service.setMaxListeners(15)

export default service
