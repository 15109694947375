<template>
	<div></div>
</template>

<script>
export default {
	name: 'UserData',
}
</script>

<style scoped></style>
