export function insufficientStock() {
	return {
		state: {
			ShowNothingMessage: false,
			ShowInsufficientResults: false,
		},
		getters: {
			getShowNothingMessage: (state) => state.ShowNothingMessage,
			getShowInsufficientResults: (state) => state.ShowInsufficientResults,
		},
		mutations: {
			mutateShowNothingMessage(state, payload) {
				state.ShowNothingMessage = payload
			},
			mutateShowInsufficientResults(state, payload) {
				state.ShowInsufficientResults = payload
			},
		},
		actions: {
			setShowNothingMessage({ commit }, payload) {
				commit('mutateShowNothingMessage', payload)
			},
			setShowInsufficientResults({ commit }, payload) {
				commit('mutateShowInsufficientResults', payload)
			},
		},
	}
}
