export function dispatchReport() {
	return {
		state: {
			ReportDispatchOurCompany: '',
			ReportDispatchCustomer: '',
			ReportDispatchCustomerNickName: '',
			ReportDispatchNumber: '',
			ReportDispatchIssuedDate: '',
			ReportDispatchOurReference: '',
			ReportDispatchCustomerReference: '',
			ReportDispatchOurAddressLine1: '',
			ReportDispatchOurAddressLine2: '',
			ReportDispatchOurAddressLine3: '',
			ReportDispatchOurAddressLine4: '',
			ReportDispatchOurOrgNum: '',
			ReportDispatchCustomerAddressLine1: '',
			ReportDispatchCustomerAddressLine2: '',
			ReportDispatchCustomerAddressLine3: '',
			ReportDispatchCustomerAddressLine4: '',
			ReportDispatchCustomerOrgNum: '',
			ReportDispatchDispatchLines: '',
			ReportDispatchOurEmail: '',
			ReportDispatchOurTel: '',
			ReportDispatchOrderNumbers: '',
			ReportDispatchReportKey: 1,
		},
		getters: {
			getReportDispatchOurCompany: (state) => state.ReportDispatchOurCompany,
			getReportDispatchCustomer: (state) => state.ReportDispatchCustomer,
			getReportDispatchCustomerNickName: (state) =>
				state.ReportDispatchCustomerNickName,
			getReportDispatchNumber: (state) => state.ReportDispatchNumber,
			getReportDispatchIssuedDate: (state) => state.ReportDispatchIssuedDate,
			getReportDispatchOurReference: (state) =>
				state.ReportDispatchOurReference,
			getReportDispatchCustomerReference: (state) =>
				state.ReportDispatchCustomerReference,
			getReportDispatchOurAddressLine1: (state) =>
				state.ReportDispatchOurAddressLine1,
			getReportDispatchOurAddressLine2: (state) =>
				state.ReportDispatchOurAddressLine2,
			getReportDispatchOurAddressLine3: (state) =>
				state.ReportDispatchOurAddressLine3,
			getReportDispatchOurAddressLine4: (state) =>
				state.ReportDispatchOurAddressLine4,
			getReportDispatchOurOrgNum: (state) => state.ReportDispatchOurOrgNum,
			getReportDispatchCustomerAddressLine1: (state) =>
				state.ReportDispatchCustomerAddressLine1,
			getReportDispatchCustomerAddressLine2: (state) =>
				state.ReportDispatchCustomerAddressLine2,
			getReportDispatchCustomerAddressLine3: (state) =>
				state.ReportDispatchCustomerAddressLine3,
			getReportDispatchCustomerAddressLine4: (state) =>
				state.ReportDispatchCustomerAddressLine4,
			getReportDispatchCustomerOrgNum: (state) =>
				state.ReportDispatchCustomerOrgNum,
			getReportDispatchDispatchLines: (state) =>
				state.ReportDispatchDispatchLines,
			getReportDispatchOurEmail: (state) => state.ReportDispatchOurEmail,
			getReportDispatchOurTel: (state) => state.ReportDispatchOurTel,
			getReportDispatchOrderNumbers: (state) =>
				state.ReportDispatchOrderNumbers,
			getReportDispatchReportKey: (state) => state.ReportDispatchReportKey,
		},
		mutations: {
			mutateReportDispatchOurCompany(state, payload) {
				state.ReportDispatchOurCompany = payload
			},
			mutateReportDispatchCustomer(state, payload) {
				state.ReportDispatchCustomer = payload
			},
			mutateReportDispatchCustomerNickName(state, payload) {
				state.ReportDispatchCustomerNickName = payload
			},
			mutateReportDispatchNumber(state, payload) {
				state.ReportDispatchNumber = payload
			},
			mutateReportDispatchIssuedDate(state, payload) {
				state.ReportDispatchIssuedDate = payload
			},
			mutateReportDispatchOurReference(state, payload) {
				state.ReportDispatchOurReference = payload
			},
			mutateReportDispatchCustomerReference(state, payload) {
				state.ReportDispatchCustomerReference = payload
			},
			mutateReportDispatchOurAddressLine1(state, payload) {
				state.ReportDispatchOurAddressLine1 = payload
			},
			mutateReportDispatchOurAddressLine2(state, payload) {
				state.ReportDispatchOurAddressLine2 = payload
			},
			mutateReportDispatchOurAddressLine3(state, payload) {
				state.ReportDispatchOurAddressLine3 = payload
			},
			mutateReportDispatchOurAddressLine4(state, payload) {
				state.ReportDispatchOurAddressLine4 = payload
			},
			mutateReportDispatchOurOrgNum(state, payload) {
				state.ReportDispatchOurOrgNum = payload
			},
			mutateReportDispatchCustomerAddressLine1(state, payload) {
				state.ReportDispatchCustomerAddressLine1 = payload
			},
			mutateReportDispatchCustomerAddressLine2(state, payload) {
				state.ReportDispatchCustomerAddressLine2 = payload
			},
			mutateReportDispatchCustomerAddressLine3(state, payload) {
				state.ReportDispatchCustomerAddressLine3 = payload
			},
			mutateReportDispatchCustomerAddressLine4(state, payload) {
				state.ReportDispatchCustomerAddressLine4 = payload
			},
			mutateReportDispatchCustomerOrgNum(state, payload) {
				state.ReportDispatchCustomerOrgNum = payload
			},
			mutateReportDispatchDispatchLines(state, payload) {
				state.ReportDispatchDispatchLines = payload
			},
			mutateReportDispatchOurEmail(state, payload) {
				state.ReportDispatchOurEmail = payload
			},
			mutateReportDispatchOurTel(state, payload) {
				state.ReportDispatchOurTel = payload
			},
			mutateReportDispatchOrderNumbers(state, payload) {
				state.ReportDispatchOrderNumbers = payload
			},
			mutateReportDispatchReportKey(state, payload) {
				state.ReportDispatchReportKey = payload
			},
		},
		actions: {
			setReportDispatchOurCompany({ commit }, payload) {
				commit('mutateReportDispatchOurCompany', payload)
			},
			setReportDispatchCustomer({ commit }, payload) {
				commit('mutateReportDispatchCustomer', payload)
			},
			setReportDispatchCustomerNickName({ commit }, payload) {
				commit('mutateReportDispatchCustomerNickName', payload)
			},
			setReportDispatchNumber({ commit }, payload) {
				commit('mutateReportDispatchNumber', payload)
			},
			setReportDispatchIssuedDate({ commit }, payload) {
				commit('mutateReportDispatchIssuedDate', payload)
			},
			setReportDispatchOurReference({ commit }, payload) {
				commit('mutateReportDispatchOurReference', payload)
			},
			setReportDispatchCustomerReference({ commit }, payload) {
				commit('mutateReportDispatchCustomerReference', payload)
			},
			setReportDispatchOurAddressLine1({ commit }, payload) {
				commit('mutateReportDispatchOurAddressLine1', payload)
			},
			setReportDispatchOurAddressLine2({ commit }, payload) {
				commit('mutateReportDispatchOurAddressLine2', payload)
			},
			setReportDispatchOurAddressLine3({ commit }, payload) {
				commit('mutateReportDispatchOurAddressLine3', payload)
			},
			setReportDispatchOurAddressLine4({ commit }, payload) {
				commit('mutateReportDispatchOurAddressLine4', payload)
			},
			setReportDispatchOurOrgNum({ commit }, payload) {
				commit('mutateReportDispatchOurOrgNum', payload)
			},
			setReportDispatchCustomerAddressLine1({ commit }, payload) {
				commit('mutateReportDispatchCustomerAddressLine1', payload)
			},
			setReportDispatchCustomerAddressLine2({ commit }, payload) {
				commit('mutateReportDispatchCustomerAddressLine2', payload)
			},
			setReportDispatchCustomerAddressLine3({ commit }, payload) {
				commit('mutateReportDispatchCustomerAddressLine3', payload)
			},
			setReportDispatchCustomerAddressLine4({ commit }, payload) {
				commit('mutateReportDispatchCustomerAddressLine4', payload)
			},
			setReportDispatchCustomerOrgNum({ commit }, payload) {
				commit('mutateReportDispatchCustomerOrgNum', payload)
			},
			setReportDispatchDispatchLines({ commit }, payload) {
				commit('mutateReportDispatchDispatchLines', payload)
			},
			setReportDispatchOurEmail({ commit }, payload) {
				commit('mutateReportDispatchOurEmail', payload)
			},
			setReportDispatchOurTel({ commit }, payload) {
				commit('mutateReportDispatchOurTel', payload)
			},
			setReportDispatchOrderNumbers({ commit }, payload) {
				commit('mutateReportDispatchOrderNumbers', payload)
			},
			setReportDispatchReportKey({ commit }, payload) {
				commit('mutateReportDispatchReportKey', payload)
			},
		},
	}
}
