<!-- @format -->

<template>
	<b-navbar>
		<template slot="brand">
			<b-navbar-item tag="router-link" :to="{ path: '/' }">
				<img src="../../assets/logo-gtbs.png" alt="GTBS Logo" />
			</b-navbar-item>
			<b-navbar-item tag="div">
				<div class="buttons">
					<a
						class="button is-primary"
						v-if="logInfo === undefined"
						v-on:click="handleUserLogin"
					>
						<strong>{{ $t('message.pages.navbar.login') }}</strong>
					</a>
				</div>
			</b-navbar-item>
			<b-navbar-item>
				<div v-if="$i18n.locale === 'en'" @click="switchLanguage">
					<img src="../../assets/en.png" height="28" />
				</div>
				<div v-if="$i18n.locale === 'se'" @click="switchLanguage">
					<img src="../../assets/se.png" height="28" />
				</div>

				<div v-if="$i18n.locale === 'tr'" @click="switchLanguage">
					<img src="../../assets/tr.png" height="28" />
				</div>
			</b-navbar-item>
		</template>
		<template slot="start"></template>
		<template slot="end"> </template>
		<template slot="burger">
			<b-navbar-item> </b-navbar-item>
		</template>
	</b-navbar>
</template>

<script>
import userLang from '../../composables/i18n/user-i18n'
import auth from '../../plugins/auth/authService'
import { ref } from '@vue/composition-api'

export default {
	name: 'NavBar',
	setup() {
		const logInfo = ref(false)
		logInfo.value = auth.loggedIn
		function handleUserLogin() {
			auth.login()
		}
		// Return
		return {
			...userLang(),
			handleUserLogin,
			logInfo,
		}
	},
}
</script>
<style scoped></style>
