import SecureLS from 'secure-ls'

const ls = new SecureLS({
	encodingType: 'des',
	encryptionSecret: 'apL27@!cas2yRt987!@TrT4368',
})
const logInfo = 'li'
const tokenInfo = 'ti'
const profileInfo = 'pi'
const userName = 'z}!@lun'
const userGroups = 'z}!@lug'
const userRoles = 'z}!@lur'
const userCurrentCompany = 'z}!@lucc'
const lsSetLogInfo = (payload) => ls.set(logInfo, payload)
const lsSetTokenInfo = (payload) => ls.set(tokenInfo, payload)
const lsSetProfileInfo = (payload) => ls.set(profileInfo, payload)
const lsSetUserName = (payload) => ls.set(userName, payload)
const lsSetUserGroups = (payload) => ls.set(userGroups, payload)
const lsSetUserRoles = (payload) => ls.set(userRoles, payload)
const lsSetUserCurrentCompany = (payload) => ls.set(userCurrentCompany, payload)

const lsGetLogInfo = () => ls.get(logInfo)
const lsGetTokenInfo = () => ls.get(tokenInfo)
const lsGetProfileInfo = () => ls.get(profileInfo)
const lsGetUserName = () => ls.get(userName)
const lsGetUserGroups = () => ls.get(userGroups)
const lsGetUserRoles = () => ls.get(userRoles)
const lsGetUserCurrentCompany = () => ls.get(userCurrentCompany)

const lsRemoveAll = () => ls.removeAll()

export {
	lsSetLogInfo,
	lsSetTokenInfo,
	lsSetProfileInfo,
	lsSetUserName,
	lsSetUserGroups,
	lsSetUserRoles,
	lsSetUserCurrentCompany,
	lsGetLogInfo,
	lsGetTokenInfo,
	lsGetProfileInfo,
	lsGetUserName,
	lsGetUserGroups,
	lsGetUserRoles,
	lsGetUserCurrentCompany,
	lsRemoveAll,
	logInfo,
	tokenInfo,
	profileInfo,
}
