export function dispatchEdit() {
	return {
		state: {
			DispatchEditTransactionID: '',
			DispatchEditDispatchNumber: '',
			DispatchEditLineInfo: '',
			DispatchEditOrderAmount: '',
			DispatchEditDispatchAmount: '',
		},
		getters: {
			getDispatchEditTransactionID: (state) => state.DispatchEditTransactionID,
			getDispatchEditDispatchNumber: (state) =>
				state.DispatchEditDispatchNumber,
			getDispatchEditLineInfo: (state) => state.DispatchEditLineInfo,
			getDispatchEditOrderAmount: (state) => state.DispatchEditOrderAmount,
			getDispatchEditDispatchAmount: (state) =>
				state.DispatchEditDispatchAmount,
		},
		mutations: {
			mutateDispatchEditTransactionID(state, payload) {
				state.DispatchEditTransactionID = payload
			},
			mutateDispatchEditDispatchNumber(state, payload) {
				state.DispatchEditDispatchNumber = payload
			},
			mutateDispatchEditLineInfo(state, payload) {
				state.DispatchEditLineInfo = payload
			},
			mutateDispatchEditOrderAmount(state, payload) {
				state.DispatchEditOrderAmount = payload
			},
			mutateDispatchEditDispatchAmount(state, payload) {
				state.DispatchEditDispatchAmount = payload
			},
		},
		actions: {
			setDispatchEditTransactionID({ commit }, payload) {
				commit('mutateDispatchEditTransactionID', payload)
			},
			setDispatchEditDispatchNumber({ commit }, payload) {
				commit('mutateDispatchEditDispatchNumber', payload)
			},
			setDispatchEditLineInfo({ commit }, payload) {
				commit('mutateDispatchEditLineInfo', payload)
			},
			setDispatchEditOrderAmount({ commit }, payload) {
				commit('mutateDispatchEditOrderAmount', payload)
			},
			setDispatchEditDispatchAmount({ commit }, payload) {
				commit('mutateDispatchEditDispatchAmount', payload)
			},
		},
	}
}
