/** @format */

export function keys() {
	return {
		state: {
			AddressListKey: 1,
			TelNumListKey: 1,
			EditStockItemKey: 1,
		},
		getters: {
			getAddressListKey: (state) => state.AddressListKey,
			getTelNumListKey: (state) => state.TelNumListKey,
			getEditStockItemKey: (state) => state.EditStockItemKey,
		},
		mutations: {
			mutateAddressListKey(state, payload) {
				state.AddressListKey = payload
			},
			mutateTelNumListKey(state, payload) {
				state.TelNumListKey = payload
			},
			mutateEditStockItemKey(state, payload) {
				state.EditStockItemKey = payload
			},
		},
		actions: {
			setAddressListKey({ commit }, payload) {
				commit('mutateAddressListKey', payload)
			},
			setTelNumListKey({ commit }, payload) {
				commit('mutateTelNumListKey', payload)
			},
			setEditStockItemKey({ commit }, payload) {
				commit('mutateEditStockItemKey', payload)
			},
		},
	}
}
