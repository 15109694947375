export function customerPriceList() {
	return {
		state: {
			PLSearchCustomerShow: true,
			PLPriceListHeadersShow: false,
			PLSelectedCustomerID: '',
			PLSelectedCustomerNickname: '',
			PLSelectedCustomerTitle: '',
			PLSearchResultsShow: true,
			PLSearchResultsKey: 1,
			PLSearchBoxShow: true,
			PLSearchBoxKey: 1,
			PLCurrentCompany: '',
			PLChangeCustomerButtonShow: false,
			PLSearchStockItemShow: false,
			PLStockItemID: '',
			PLStockItemName: '',
			PLStockItemPrice: 0,
			PLCustomersPrice: 0,
			PLCustomersPriceShow: false,
			PLCustomersPriceKey: 1,
			PLCustomersPriceLinesShow: false,
			PLCustomersPriceLinesKey: 1,
		},
		getters: {
			getPLSearchCustomerShow: (state) => state.PLSearchCustomerShow,
			getPLPriceListHeadersShow: (state) => state.PLPriceListHeadersShow,
			getPLSelectedCustomerID: (state) => state.PLSelectedCustomerID,
			getPLSelectedCustomerNickname: (state) =>
				state.PLSelectedCustomerNickname,
			getPLSelectedCustomerTitle: (state) => state.PLSelectedCustomerTitle,
			getPLSearchResultsShow: (state) => state.PLSearchResultsShow,
			getPLSearchResultsKey: (state) => state.PLSearchResultsKey,
			getPLSearchBoxShow: (state) => state.PLSearchBoxShow,
			getPLSearchBoxKey: (state) => state.PLSearchBoxKey,
			getPLCurrentCompany: (state) => state.PLCurrentCompany,
			getPLChangeCustomerButtonShow: (state) =>
				state.PLChangeCustomerButtonShow,
			getPLSearchStockItemShow: (state) => state.PLSearchStockItemShow,
			getPLStockItemID: (state) => state.PLStockItemID,
			getPLStockItemName: (state) => state.PLStockItemName,
			getPLStockItemPrice: (state) => state.PLStockItemPrice,
			getPLCustomersPrice: (state) => state.PLCustomersPrice,
			getPLCustomersPriceShow: (state) => state.PLCustomersPriceShow,
			getPLCustomersPriceKey: (state) => state.PLCustomersPriceKey,
			getPLCustomersPriceLinesShow: (state) => state.PLCustomersPriceLinesShow,
			getPLCustomersPriceLinesKey: (state) => state.PLCustomersPriceLinesKey,
		},
		mutations: {
			mutatePLSearchCustomerShow(state, payload) {
				state.PLSearchCustomerShow = payload
			},
			mutatePLPriceListHeadersShow(state, payload) {
				state.PLPriceListHeadersShow = payload
			},
			mutatePLSelectedCustomerID(state, payload) {
				state.PLSelectedCustomerID = payload
			},
			mutatePLSelectedCustomerNickname(state, payload) {
				state.PLSelectedCustomerNickname = payload
			},
			mutatePLSelectedCustomerTitle(state, payload) {
				state.PLSelectedCustomerTitle = payload
			},
			mutatePLSearchResultsShow(state, payload) {
				state.PLSearchResultsShow = payload
			},
			mutatePLSearchResultsKey(state, payload) {
				state.PLSearchResultsKey = payload
			},
			mutatePLSearchBoxShow(state, payload) {
				state.PLSearchBoxShow = payload
			},
			mutatePLSearchBoxKey(state, payload) {
				state.PLSearchBoxKey = payload
			},
			mutatePLCurrentCompany(state, payload) {
				state.PLCurrentCompany = payload
			},
			mutatePLChangeCustomerButtonShow(state, payload) {
				state.PLChangeCustomerButtonShow = payload
			},
			mutatePLSearchStockItemShow(state, payload) {
				state.PLSearchStockItemShow = payload
			},
			mutatePLStockItemID(state, payload) {
				state.PLStockItemID = payload
			},
			mutatePLStockItemName(state, payload) {
				state.PLStockItemName = payload
			},
			mutatePLStockItemPrice(state, payload) {
				state.PLStockItemPrice = payload
			},
			mutatePLCustomersPrice(state, payload) {
				state.PLCustomersPrice = payload
			},
			mutatePLCustomersPriceShow(state, payload) {
				state.PLCustomersPriceShow = payload
			},
			mutatePLCustomersPriceKey(state, payload) {
				state.PLCustomersPriceKey = payload
			},
			mutatePLCustomersPriceLinesShow(state, payload) {
				state.PLCustomersPriceLinesShow = payload
			},
			mutatePLCustomersPriceLinesKey(state, payload) {
				state.PLCustomersPriceLinesKey = payload
			},
		},
		actions: {
			setPLSearchCustomerShow({ commit }, payload) {
				commit('mutatePLSearchCustomerShow', payload)
			},
			setPLPriceListHeadersShow({ commit }, payload) {
				commit('mutatePLPriceListHeadersShow', payload)
			},
			setPLSelectedCustomerID({ commit }, payload) {
				commit('mutatePLSelectedCustomerID', payload)
			},
			setPLSelectedCustomerNickname({ commit }, payload) {
				commit('mutatePLSelectedCustomerNickname', payload)
			},
			setPLSelectedCustomerTitle({ commit }, payload) {
				commit('mutatePLSelectedCustomerTitle', payload)
			},
			setPLSearchResultsShow({ commit }, payload) {
				commit('mutatePLSearchResultsShow', payload)
			},
			setPLSearchResultsKey({ commit }, payload) {
				commit('mutatePLSearchResultsKey', payload)
			},
			setPLSearchBoxShow({ commit }, payload) {
				commit('mutatePLSearchBoxShow', payload)
			},
			setPLSearchBoxKey({ commit }, payload) {
				commit('mutatePLSearchBoxKey', payload)
			},
			setPLCurrentCompany({ commit }, payload) {
				commit('mutatePLCurrentCompany', payload)
			},
			setPLChangeCustomerButtonShow({ commit }, payload) {
				commit('mutatePLChangeCustomerButtonShow', payload)
			},
			setPLSearchStockItemShow({ commit }, payload) {
				commit('mutatePLSearchStockItemShow', payload)
			},
			setPLStockItemID({ commit }, payload) {
				commit('mutatePLStockItemID', payload)
			},
			setPLStockItemName({ commit }, payload) {
				commit('mutatePLStockItemName', payload)
			},
			setPLStockItemPrice({ commit }, payload) {
				commit('mutatePLStockItemPrice', payload)
			},
			setPLCustomersPrice({ commit }, payload) {
				commit('mutatePLCustomersPrice', payload)
			},
			setPLCustomersPriceShow({ commit }, payload) {
				commit('mutatePLCustomersPriceShow', payload)
			},
			setPLCustomersPriceKey({ commit }, payload) {
				commit('mutatePLCustomersPriceKey', payload)
			},
			setPLCustomersPriceLinesShow({ commit }, payload) {
				commit('mutatePLCustomersPriceLinesShow', payload)
			},
			setPLCustomersPriceLinesKey({ commit }, payload) {
				commit('mutatePLCustomersPriceLinesKey', payload)
			},
		},
	}
}
