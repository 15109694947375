export function invoiceReport() {
	return {
		state: {
			ReportInvoiceOurCompany: '',
			ReportInvoiceCustomer: '',
			ReportInvoiceCustomerNickName: '',
			ReportInvoiceNumber: '',
			ReportInvoiceIssuedDate: '',
			ReportInvoiceDueDate: '',
			ReportInvoiceOurReference: '',
			ReportInvoiceCustomerReference: '',
			ReportInvoiceOurAddressLine1: '',
			ReportInvoiceOurAddressLine2: '',
			ReportInvoiceOurAddressLine3: '',
			ReportInvoiceOurAddressLine4: '',
			ReportInvoiceOurOrgNum: '',
			ReportInvoiceCustomerAddressLine1: '',
			ReportInvoiceCustomerAddressLine2: '',
			ReportInvoiceCustomerAddressLine3: '',
			ReportInvoiceCustomerAddressLine4: '',
			ReportInvoiceCustomerOrgNum: '',
			ReportInvoiceInvoiceLines: '',
			ReportInvoiceOurEmail: '',
			ReportInvoiceOurTel: '',
			ReportInvoiceDispatchNumbers: '',
			ReportInvoiceOrderNumbers: '',
			ReportInvoiceSubtotal: '',
			ReportInvoiceVatArr: '',
			ReportInvoiceReportKey: 1,
			ReportInvoiceRounding: 1,
			ReportInvoiceToPay: 1,
		},
		getters: {
			getReportInvoiceOurCompany: (state) => state.ReportInvoiceOurCompany,
			getReportInvoiceCustomer: (state) => state.ReportInvoiceCustomer,
			getReportInvoiceCustomerNickName: (state) =>
				state.ReportInvoiceCustomerNickName,
			getReportInvoiceNumber: (state) => state.ReportInvoiceNumber,
			getReportInvoiceIssuedDate: (state) => state.ReportInvoiceIssuedDate,
			getReportInvoiceDueDate: (state) => state.ReportInvoiceDueDate,
			getReportInvoiceOurReference: (state) => state.ReportInvoiceOurReference,
			getReportInvoiceCustomerReference: (state) =>
				state.ReportInvoiceCustomerReference,
			getReportInvoiceOurAddressLine1: (state) =>
				state.ReportInvoiceOurAddressLine1,
			getReportInvoiceOurAddressLine2: (state) =>
				state.ReportInvoiceOurAddressLine2,
			getReportInvoiceOurAddressLine3: (state) =>
				state.ReportInvoiceOurAddressLine3,
			getReportInvoiceOurAddressLine4: (state) =>
				state.ReportInvoiceOurAddressLine4,
			getReportInvoiceOurOrgNum: (state) => state.ReportInvoiceOurOrgNum,
			getReportInvoiceCustomerAddressLine1: (state) =>
				state.ReportInvoiceCustomerAddressLine1,
			getReportInvoiceCustomerAddressLine2: (state) =>
				state.ReportInvoiceCustomerAddressLine2,
			getReportInvoiceCustomerAddressLine3: (state) =>
				state.ReportInvoiceCustomerAddressLine3,
			getReportInvoiceCustomerAddressLine4: (state) =>
				state.ReportInvoiceCustomerAddressLine4,
			getReportInvoiceCustomerOrgNum: (state) =>
				state.ReportInvoiceCustomerOrgNum,
			getReportInvoiceInvoiceLines: (state) => state.ReportInvoiceInvoiceLines,
			getReportInvoiceOurEmail: (state) => state.ReportInvoiceOurEmail,
			getReportInvoiceOurTel: (state) => state.ReportInvoiceOurTel,
			getReportInvoiceDispatchNumbers: (state) =>
				state.ReportInvoiceDispatchNumbers,
			getReportInvoiceOrderNumbers: (state) => state.ReportInvoiceOrderNumbers,
			getReportInvoiceSubtotal: (state) => state.ReportInvoiceSubtotal,
			getReportInvoiceVatArr: (state) => state.ReportInvoiceVatArr,
			getReportInvoiceReportKey: (state) => state.ReportInvoiceReportKey,
			getReportInvoiceRounding: (state) => state.ReportInvoiceRounding,
			getReportInvoiceToPay: (state) => state.ReportInvoiceToPay,
		},
		mutations: {
			mutateReportInvoiceOurCompany(state, payload) {
				state.ReportInvoiceOurCompany = payload
			},
			mutateReportInvoiceCustomer(state, payload) {
				state.ReportInvoiceCustomer = payload
			},
			mutateReportInvoiceCustomerNickName(state, payload) {
				state.ReportInvoiceCustomerNickName = payload
			},
			mutateReportInvoiceNumber(state, payload) {
				state.ReportInvoiceNumber = payload
			},
			mutateReportInvoiceIssuedDate(state, payload) {
				state.ReportInvoiceIssuedDate = payload
			},
			mutateReportInvoiceDueDate(state, payload) {
				state.ReportInvoiceDueDate = payload
			},
			mutateReportInvoiceOurReference(state, payload) {
				state.ReportInvoiceOurReference = payload
			},
			mutateReportInvoiceCustomerReference(state, payload) {
				state.ReportInvoiceCustomerReference = payload
			},
			mutateReportInvoiceOurAddressLine1(state, payload) {
				state.ReportInvoiceOurAddressLine1 = payload
			},
			mutateReportInvoiceOurAddressLine2(state, payload) {
				state.ReportInvoiceOurAddressLine2 = payload
			},
			mutateReportInvoiceOurAddressLine3(state, payload) {
				state.ReportInvoiceOurAddressLine3 = payload
			},
			mutateReportInvoiceOurAddressLine4(state, payload) {
				state.ReportInvoiceOurAddressLine4 = payload
			},
			mutateReportInvoiceOurOrgNum(state, payload) {
				state.ReportInvoiceOurOrgNum = payload
			},
			mutateReportInvoiceCustomerAddressLine1(state, payload) {
				state.ReportInvoiceCustomerAddressLine1 = payload
			},
			mutateReportInvoiceCustomerAddressLine2(state, payload) {
				state.ReportInvoiceCustomerAddressLine2 = payload
			},
			mutateReportInvoiceCustomerAddressLine3(state, payload) {
				state.ReportInvoiceCustomerAddressLine3 = payload
			},
			mutateReportInvoiceCustomerAddressLine4(state, payload) {
				state.ReportInvoiceCustomerAddressLine4 = payload
			},
			mutateReportInvoiceCustomerOrgNum(state, payload) {
				state.ReportInvoiceCustomerOrgNum = payload
			},
			mutateReportInvoiceInvoiceLines(state, payload) {
				state.ReportInvoiceInvoiceLines = payload
			},
			mutateReportInvoiceOurEmail(state, payload) {
				state.ReportInvoiceOurEmail = payload
			},
			mutateReportInvoiceOurTel(state, payload) {
				state.ReportInvoiceOurTel = payload
			},
			mutateReportInvoiceDispatchNumbers(state, payload) {
				state.ReportInvoiceDispatchNumbers = payload
			},
			mutateReportInvoiceOrderNumbers(state, payload) {
				state.ReportInvoiceOrderNumbers = payload
			},
			mutateReportInvoiceSubtotal(state, payload) {
				state.ReportInvoiceSubtotal = payload
			},
			mutateReportInvoiceVatArr(state, payload) {
				state.ReportInvoiceVatArr = payload
			},
			mutateReportInvoiceReportKey(state, payload) {
				state.ReportInvoiceReportKey = payload
			},
			mutateReportInvoiceRounding(state, payload) {
				state.ReportInvoiceRounding = payload
			},
			mutateReportInvoiceToPay(state, payload) {
				state.ReportInvoiceToPay = payload
			},
		},
		actions: {
			setReportInvoiceOurCompany({ commit }, payload) {
				commit('mutateReportInvoiceOurCompany', payload)
			},
			setReportInvoiceCustomer({ commit }, payload) {
				commit('mutateReportInvoiceCustomer', payload)
			},
			setReportInvoiceCustomerNickName({ commit }, payload) {
				commit('mutateReportInvoiceCustomerNickName', payload)
			},
			setReportInvoiceNumber({ commit }, payload) {
				commit('mutateReportInvoiceNumber', payload)
			},
			setReportInvoiceIssuedDate({ commit }, payload) {
				commit('mutateReportInvoiceIssuedDate', payload)
			},
			setReportInvoiceDueDate({ commit }, payload) {
				commit('mutateReportInvoiceDueDate', payload)
			},
			setReportInvoiceOurReference({ commit }, payload) {
				commit('mutateReportInvoiceOurReference', payload)
			},
			setReportInvoiceCustomerReference({ commit }, payload) {
				commit('mutateReportInvoiceCustomerReference', payload)
			},
			setReportInvoiceOurAddressLine1({ commit }, payload) {
				commit('mutateReportInvoiceOurAddressLine1', payload)
			},
			setReportInvoiceOurAddressLine2({ commit }, payload) {
				commit('mutateReportInvoiceOurAddressLine2', payload)
			},
			setReportInvoiceOurAddressLine3({ commit }, payload) {
				commit('mutateReportInvoiceOurAddressLine3', payload)
			},
			setReportInvoiceOurAddressLine4({ commit }, payload) {
				commit('mutateReportInvoiceOurAddressLine4', payload)
			},
			setReportInvoiceOurOrgNum({ commit }, payload) {
				commit('mutateReportInvoiceOurOrgNum', payload)
			},
			setReportInvoiceCustomerAddressLine1({ commit }, payload) {
				commit('mutateReportInvoiceCustomerAddressLine1', payload)
			},
			setReportInvoiceCustomerAddressLine2({ commit }, payload) {
				commit('mutateReportInvoiceCustomerAddressLine2', payload)
			},
			setReportInvoiceCustomerAddressLine3({ commit }, payload) {
				commit('mutateReportInvoiceCustomerAddressLine3', payload)
			},
			setReportInvoiceCustomerAddressLine4({ commit }, payload) {
				commit('mutateReportInvoiceCustomerAddressLine4', payload)
			},
			setReportInvoiceCustomerOrgNum({ commit }, payload) {
				commit('mutateReportInvoiceCustomerOrgNum', payload)
			},
			setReportInvoiceInvoiceLines({ commit }, payload) {
				commit('mutateReportInvoiceInvoiceLines', payload)
			},
			setReportInvoiceOurEmail({ commit }, payload) {
				commit('mutateReportInvoiceOurEmail', payload)
			},
			setReportInvoiceOurTel({ commit }, payload) {
				commit('mutateReportInvoiceOurTel', payload)
			},
			setReportInvoiceDispatchNumbers({ commit }, payload) {
				commit('mutateReportInvoiceDispatchNumbers', payload)
			},
			setReportInvoiceOrderNumbers({ commit }, payload) {
				commit('mutateReportInvoiceOrderNumbers', payload)
			},
			setReportInvoiceSubtotal({ commit }, payload) {
				commit('mutateReportInvoiceSubtotal', payload)
			},
			setReportInvoiceVatArr({ commit }, payload) {
				commit('mutateReportInvoiceVatArr', payload)
			},
			setReportInvoiceReportKey({ commit }, payload) {
				commit('mutateReportInvoiceReportKey', payload)
			},
			setReportInvoiceRounding({ commit }, payload) {
				commit('mutateReportInvoiceRounding', payload)
			},
			setReportInvoiceToPay({ commit }, payload) {
				commit('mutateReportInvoiceToPay', payload)
			},
		},
	}
}
