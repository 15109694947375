export function stockTabKey() {
	return {
		state: {
			AddStockItemTabKey: 1,
			InsufficientItemsTabKey: 1,
			StockGroupsTabKey: 1,
			StockUnitsTabKey: 1,
			VatRatesTabKey: 1,
		},
		getters: {
			getAddStockItemTabKey: (state) => state.AddStockItemTabKey,
		},
		mutations: {
			mutateAddStockItemTabKey(state, payload) {
				state.AddStockItemTabKey = payload
			},
		},
		actions: {
			setAddStockItemTabKey({ commit }, payload) {
				commit('mutateAddStockItemTabKey', payload)
			},
		},
	}
}
