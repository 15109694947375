<template>
	<div>
		<div>
			<img
				style="width: 30px; padding-top: 2px"
				src="../../assets/logo-gtbs.png"
				alt="GTBS Logo"
			/>
			<b-button class="is-success btn-footer">v.{{ appVersion }}</b-button>
			<b-button class="is-danger btn-footer">{{ userName }}</b-button>
			<b-button class="is-info btn-footer">{{ userCurrentCompany }}</b-button>
		</div>
	</div>
</template>
<script>
import { computed, getCurrentInstance, ref } from '@vue/composition-api'
import userLang from '../../composables/i18n/user-i18n'
import { lsGetLogInfo } from '@/plugins/lsstore'
import Store from '@/store'

export default {
	setup() {
		let vm = getCurrentInstance()
		const userName = computed(() => Store.getters.getUserName.toUpperCase())
		const userRoles = computed(() => Store.getters.getUserRoles)
		const userGroups = computed(() => Store.getters.getUserGroups)
		const userCurrentCompany = computed(() =>
			Store.getters.getUserCurrentCompany.toUpperCase(),
		)
		const logInfo = ref('')
		logInfo.value = lsGetLogInfo('li')
		function logOut() {
			vm.$auth.logOut()
			console.log('logged out')
		}
		const appVersion = computed(() => process.env.VERSION)
		// Store.dispatch('setAppVersion', appVersion)
		// return
		return {
			...userLang(),
			logInfo,
			userName,
			userRoles,
			userGroups,
			userCurrentCompany,
			logOut,
			appVersion,
		}
	},
}
</script>
<style scoped>
.btn-footer {
	margin-left: 20px;
}
</style>
