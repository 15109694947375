export function telephone() {
	return {
		state: {
			telephoneNumber: '0764344949',
			contactPerson: '',
			remarks: '',
			isActive: true,
			formAddTelNumKey: 1,
		},
		getters: {
			getTelephoneNumber: (state) => state.telephoneNumber,
			getContactPerson: (state) => state.contactPerson,
			getRemarks: (state) => state.remarks,
			getIsActive: (state) => state.isActive,
			getFormAddTelNumKey: (state) => state.formAddTelNumKey,
		},
		mutations: {
			mutateTelephoneNumber(state, payload) {
				state.telephoneNumber = payload
			},
			mutateContactPerson(state, payload) {
				state.contactPerson = payload
			},
			mutateRemarks(state, payload) {
				state.remarks = payload
			},
			mutateIsActive(state, payload) {
				state.isActive = payload
			},
			mutateFormAddTelNumKey(state, payload) {
				state.formAddTelNumKey = payload
			},
		},
		actions: {
			setTelephoneNumber({ commit }, payload) {
				commit('mutateTelephoneNumber', payload)
			},
			setContactPerson({ commit }, payload) {
				commit('mutateContactPerson', payload)
			},
			setRemarks({ commit }, payload) {
				commit('mutateRemarks', payload)
			},
			setIsActive({ commit }, payload) {
				commit('mutateIsActive', payload)
			},
			setFormAddTelNumKey({ commit }, payload) {
				commit('mutateFormAddTelNumKey', payload)
			},
		},
	}
}
