export function invoiceShow() {
	return {
		state: {
			InvoiceHeaderShow: true,
			InvoiceGetNumberShow: false,
			InvoiceCustomersShow: true,
			InvoiceSelectedCustomerShow: false,
			InvoiceLinesShow: false,
			InvoiceLineEditShow: false,
			InvoiceFooterButtonsShow: false,
		},
		getters: {
			getInvoiceHeaderShow: (state) => state.InvoiceHeaderShow,
			getInvoiceGetNumberShow: (state) => state.InvoiceGetNumberShow,
			getInvoiceCustomersShow: (state) => state.InvoiceCustomersShow,
			getInvoiceSelectedCustomerShow: (state) =>
				state.InvoiceSelectedCustomerShow,
			getInvoiceLinesShow: (state) => state.InvoiceLinesShow,
			getInvoiceLineEditShow: (state) => state.InvoiceLineEditShow,
			getInvoiceFooterButtonsShow: (state) => state.InvoiceFooterButtonsShow,
		},
		mutations: {
			mutateInvoiceHeaderShow(state, payload) {
				state.InvoiceHeaderShow = payload
			},
			mutateInvoiceGetNumberShow(state, payload) {
				state.InvoiceGetNumberShow = payload
			},
			mutateInvoiceCustomersShow(state, payload) {
				state.InvoiceCustomersShow = payload
			},
			mutateInvoiceSelectedCustomerShow(state, payload) {
				state.InvoiceSelectedCustomerShow = payload
			},
			mutateInvoiceLinesShow(state, payload) {
				state.InvoiceLinesShow = payload
			},
			mutateInvoiceLineEditShow(state, payload) {
				state.InvoiceLineEditShow = payload
			},
			mutateInvoiceFooterButtonsShow(state, payload) {
				state.InvoiceFooterButtonsShow = payload
			},
		},
		actions: {
			setInvoiceHeaderShow({ commit }, payload) {
				commit('mutateInvoiceHeaderShow', payload)
			},
			setInvoiceGetNumberShow({ commit }, payload) {
				commit('mutateInvoiceGetNumberShow', payload)
			},
			setInvoiceCustomersShow({ commit }, payload) {
				commit('mutateInvoiceCustomersShow', payload)
			},
			setInvoiceSelectedCustomerShow({ commit }, payload) {
				commit('mutateInvoiceSelectedCustomerShow', payload)
			},
			setInvoiceLinesShow({ commit }, payload) {
				commit('mutateInvoiceLinesShow', payload)
			},
			setInvoiceLineEditShow({ commit }, payload) {
				commit('mutateInvoiceLineEditShow', payload)
			},
			setInvoiceFooterButtonsShow({ commit }, payload) {
				commit('mutateInvoiceFooterButtonsShow', payload)
			},
		},
	}
}
