export function invoiceEdit() {
	return {
		state: {
			InvoiceEditTransactionID: '',
			InvoiceEditInvoiceNumber: '',
			InvoiceEditLineInfo: '',
			InvoiceEditOrderAmount: '',
			InvoiceEditInvoiceAmount: '',
		},
		getters: {
			getInvoiceEditTransactionID: (state) => state.InvoiceEditTransactionID,
			getInvoiceEditInvoiceNumber: (state) => state.InvoiceEditInvoiceNumber,
			getInvoiceEditLineInfo: (state) => state.InvoiceEditLineInfo,
			getInvoiceEditOrderAmount: (state) => state.InvoiceEditOrderAmount,
			getInvoiceEditInvoiceAmount: (state) => state.InvoiceEditInvoiceAmount,
		},
		mutations: {
			mutateInvoiceEditTransactionID(state, payload) {
				state.InvoiceEditTransactionID = payload
			},
			mutateInvoiceEditInvoiceNumber(state, payload) {
				state.InvoiceEditInvoiceNumber = payload
			},
			mutateInvoiceEditLineInfo(state, payload) {
				state.InvoiceEditLineInfo = payload
			},
			mutateInvoiceEditOrderAmount(state, payload) {
				state.InvoiceEditOrderAmount = payload
			},
			mutateInvoiceEditInvoiceAmount(state, payload) {
				state.InvoiceEditInvoiceAmount = payload
			},
		},
		actions: {
			setInvoiceEditTransactionID({ commit }, payload) {
				commit('mutateInvoiceEditTransactionID', payload)
			},
			setInvoiceEditInvoiceNumber({ commit }, payload) {
				commit('mutateInvoiceEditInvoiceNumber', payload)
			},
			setInvoiceEditLineInfo({ commit }, payload) {
				commit('mutateInvoiceEditLineInfo', payload)
			},
			setInvoiceEditOrderAmount({ commit }, payload) {
				commit('mutateInvoiceEditOrderAmount', payload)
			},
			setInvoiceEditInvoiceAmount({ commit }, payload) {
				commit('mutateInvoiceEditInvoiceAmount', payload)
			},
		},
	}
}
